import { combineReducers } from 'redux';
import enrolleeReducer from './enrollee';
import invoiceReducer from './invoice';
import userReducer from './user';
import displayReducer from './display';
import { reducer as formReducer } from 'redux-form';
import { sessionReducer } from 'redux-react-session';
import clientReducer from './client';

const rootReducer = combineReducers({
    enrollees: enrolleeReducer,
    invoice: invoiceReducer,
    user: userReducer,
    form: formReducer,
    session: sessionReducer,
    display: displayReducer,
    client: clientReducer
});

export default rootReducer;
