import React from 'react';
import PropTypes from 'prop-types';

class SimplePageHeader extends React.Component{

    render(){
        const {title, logo} = this.props;

        return (
            <div className="top-right content">
                <div className="header" style={{height:"auto",justifyContent:"center", padding:"3rem 2rem"}}>
                    <div className="logo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="header__title">
                        <h2>{title}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

SimplePageHeader.propTypes = {
    title: PropTypes.string,
    logo: PropTypes.string
};


export default (SimplePageHeader)