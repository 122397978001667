import React from 'react';
import {Field, reduxForm} from 'redux-form';

class ChangePasswordForm extends React.Component {

  render() {
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <form onSubmit={handleSubmit} className="dashboard-form">
          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap">
              <span className="profile-item-wrapper">
                <label htmlFor="" className="profile-item__label">Old Password</label>
                <Field component="input" className="dashboard-form__input" type="password" name="old_password" placeholder=""/>
              </span>
              <span className="profile-item-wrapper">
                <label className="profile-item__label" htmlFor="">New Password</label>
                <Field component="input" className="dashboard-form__input" type="password" name="new_password" placeholder=""/>
              </span>
              <span className="profile-item-wrapper">
                <label className="profile-item__label" htmlFor="">Confirm New Password</label>
                <Field component="input" className="dashboard-form__input" type="password" name="confirm_new_password" placeholder=""/>
              </span>
              <Field component="input" type="hidden" name="access_token" style={{display: "none"}}/>
            </div>
          </div>
          <button className="dashboard__primary-btn dashboard-form__btn" disabled={pristine || submitting}>Change Password</button>
      </form>
    )
  }
}


ChangePasswordForm = reduxForm({
  form: "changePasswordForm"
})(ChangePasswordForm);

export default ChangePasswordForm;
