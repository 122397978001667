import { sessionService } from 'redux-react-session';
import userService from '../services/userService';
import { CLIENT_ACTION_TYPES } from './actionTypes';

export const getClient = () => {
    return {
        type: CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL
    };
};

export const getClientSuccess = (client) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL_SUCCESS,
        data: client
    };
};

export const getClientFailed = (error) => {
    return {
        type: CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL_FAILED,
        data: error
    };
};

export const loadClient = (email) => {
    return (dispatch) => {
        dispatch(getClient());
        userService
            .getRetailClient(email)
            .then((data) => {
                if (data && data.clientId) {
                    dispatch(getClientSuccess(data));
                    sessionService.loadUser().then((user) => {
                        const _user = { ...user, clientId: data.clientId };
                        sessionService.saveUser(_user);
                    });
                } else {
                    window.location.href = '/logout';
                }
            })
            .catch((err) => {
                dispatch(getClientFailed({ message: err.message, code: err.code }));
            });
    };
};
