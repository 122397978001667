import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import utilityService from '../../services/utilityService';
import ImageInput from './inputs/ImageInput';
import DateTime from 'react-datetime';
import './inputs/styles/datepicker.css';
import moment from 'moment';
import LoadingScreen from '../LoadingScreen';
import FilterableSelect from './inputs/FilterableSelect';
import { features } from '../../services/daara-config.json';

class SignUpForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            lastPage: 4, //can vary depending on the enrollee type,
            isLoadingSection: { profilePicture: false }
        };
    }

    onNextPage = (e) => {
        e.preventDefault();
        let nextPage = this.state.currentPage + 1;

        //selected State check
        if (
            this.state.currentPage === 2 &&
            (!this.props.selectedState || Number(this.props.selectedState) === 0)
        ) {
            this.props.onError({ message: 'Please select a state' });
            --nextPage;
        } else {
            this.props.onError();
        }

        this.setState({
            currentPage: nextPage
        });
    };

    onPrevPage = () => {
        let prevPage = this.state.currentPage - 1;
        this.setState({
            currentPage: prevPage
        });
    };

    componentDidMount() {
        const { dispatch, user } = this.props;

        dispatch(change('signUpForm', 'first_name', user.first_name));
        dispatch(change('signUpForm', 'last_name', user.last_name));
        dispatch(change('signUpForm', 'email_address', user.email_address));
        dispatch(change('signUpForm', 'primary_phone_number', user.phone_number));
        dispatch(change('signUpForm', 'access_token', user.accessToken));
        if (user.hash) {
            dispatch(change('signUpForm', 'enrollee_type', user.enrolleeType));
            dispatch(change('signUpForm', 'hash', user.hash));
        }
        dispatch(change('signUpForm', 'has_smartphone', '1'));
    }

    onImageUploaded = (img) => {
        const { dispatch, user, onError } = this.props;
        if (features.enrolleeService.completeProfile) {
            return dispatch(change('signUpForm', 'profile_picture', img));
        }
        //set isLoadingSection in state to true
        //upload img
        //get filename from server
        //change form value
        //set isLoadingSection to false

        this.setState({
            isLoadingSection: { ...this.state.isLoadingSection, profilePicture: true }
        });

        utilityService
            .uploadFile(user.accessToken, img, 'profile_picture')
            .then((filename) => {
                dispatch(change('signUpForm', 'profile_picture_filename', filename));
                dispatch(change('signUpForm', 'profile_picture', img));
                this.setState({
                    error: null,
                    isLoadingSection: { ...this.state.isLoadingSection, profilePicture: false }
                });
            })
            .catch((e) => {
                onError({
                    message: 'Unable to upload picture.',
                    code: -1
                });
                this.setState({
                    isLoadingSection: { ...this.state.isLoadingSection, profilePicture: false }
                });
                console.log('Error uploading image', e);
            });
    };

    onDateSet = (date) => {
        const { dispatch } = this.props;
        dispatch(change('signUpForm', 'date_of_birth', moment(date).format('YYYY-MM-DD')));
    };

    render() {
        const {
            error,
            selectedState,
            dateOfBirth,
            profilePicture,
            preferredHospitalLocation,
            data,
            enrolleeType,
            handleSubmit,
            user,
            pristine,
            submitting
        } = this.props;
        const { currentPage, lastPage, isLoadingSection } = this.state;

        return (
            <form
                onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit}
                className="form"
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'evenly' }}>
                    {[1, 2, 3, 4].map((x) => {
                        return <PageIndicator key={x} isActive={x === currentPage} />;
                    })}
                </div>
                {currentPage === 1 && (
                    <PersonalInfoPage
                        user={user}
                        data={data}
                        onDateSet={this.onDateSet}
                        dob={dateOfBirth}
                    />
                )}
                {currentPage === 2 && (
                    <ContactInfoPage selectedState={selectedState} user={user} data={data} />
                )}
                {currentPage === 3 && (
                    <MedicalInfoPage
                        user={user}
                        data={data}
                        selectedState={selectedState}
                        preferredHospitalLocation={preferredHospitalLocation}
                    />
                )}
                {currentPage === 4 && (
                    <ExtraInfoPage
                        user={user}
                        data={data}
                        profilePicture={profilePicture}
                        onImageSet={this.onImageUploaded}
                        isLoadingPhoto={isLoadingSection.profilePicture}
                        enrolleeType={enrolleeType}
                    />
                )}

                {error && (
                    <div style={{ backgroundColor: 'red', color: '#FFF', padding: '10px' }}>
                        {error}
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        marginTop: '4rem'
                    }}
                >
                    <Field component="input" type="hidden" name="page" value={currentPage} />
                    {currentPage > 1 && (
                        <input
                            type="button"
                            value="Previous"
                            className="dashboard__primary-btn dashboard-form__btn"
                            onClick={this.onPrevPage}
                            style={{ float: 'left', marginTop: 0 }}
                        />
                    )}

                    <div
                        style={{ width: '100%', textAlign: 'center', fontSize: '1.4rem' }}
                    >{`${currentPage} / ${lastPage}`}</div>

                    {currentPage >= 1 && currentPage < lastPage && (
                        <input
                            type="submit"
                            className="dashboard__primary-btn dashboard-form__btn"
                            value="Next"
                            style={{ float: 'right', marginTop: 0 }}
                        />
                    )}
                    {currentPage === lastPage && (
                        <input
                            type="submit"
                            className="dashboard__primary-btn dashboard-form__btn"
                            value="Submit"
                            disabled={pristine || submitting}
                            style={{ float: 'right', marginTop: 0 }}
                        />
                    )}
                </div>
            </form>
        );
    }
}

const Required = () => <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>;

const PersonalInfoPage = (props) => {
    const { dob } = props;

    return (
        <div style={{ marginTop: '15px' }}>
            <h2 className="dashboard-card__title-text">Personal Information</h2>
            <p style={{ fontStyle: 'italic', fontSize: '1.2rem', color: 'rgb(76, 76, 76)' }}>
                <strong>NOTE:</strong>Fields marked (<Required />) are required.
            </p>
            <div style={{ marginTop: '10px' }}>
                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Firstname <Required />
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="text"
                        name="first_name"
                        placeholder=""
                        required
                    />
                </span>

                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Lastname <Required />
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="text"
                        name="last_name"
                        placeholder=""
                        required
                    />
                </span>

                {features.enrolleeService.assign ? (
                    <span className="dashboard-form-item">
                        <label htmlFor="" className="profile-item__label">
                            Sex <Required />
                        </label>
                        <span className="dashboard-form__checklist-wrap">
                            <span className="dashboard-form-item__radio-check-btn">
                                <Field
                                    name="gender"
                                    component="input"
                                    type="radio"
                                    value="male"
                                    id="male"
                                />
                                <label htmlFor="male">Male</label>
                            </span>
                            <span className="dashboard-form-item__radio-check-btn">
                                <Field
                                    name="gender"
                                    component="input"
                                    type="radio"
                                    value="female"
                                    id="female"
                                />
                                <label htmlFor="female">Female</label>
                            </span>
                        </span>
                    </span>
                ) : (
                    <span className="dashboard-form-item">
                        <label htmlFor="" className="profile-item__label">
                            Sex <Required />
                        </label>
                        <span className="dashboard-form__checklist-wrap">
                            <span className="dashboard-form-item__radio-check-btn">
                                <Field
                                    name="sex"
                                    component="input"
                                    type="radio"
                                    value="m"
                                    id="male"
                                />
                                <label htmlFor="male">Male</label>
                            </span>
                            <span className="dashboard-form-item__radio-check-btn">
                                <Field
                                    name="sex"
                                    component="input"
                                    type="radio"
                                    value="f"
                                    id="female"
                                />
                                <label htmlFor="female">Female</label>
                            </span>
                        </span>
                    </span>
                )}

                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Date of Birth <Required />
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="hidden"
                        name="date_of_birth"
                        placeholder=""
                        required
                    />
                    <DateTime
                        closeOnSelect
                        viewMode="years"
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                        inputProps={{
                            className: 'dashboard-form__input',
                            required: true,
                            placeholder: 'YYYY-MM-DD',
                            readOnly: true
                        }}
                        onChange={props.onDateSet}
                        isValidDate={(curr) => curr.year() <= DateTime.moment().year()}
                        value={dob}
                    />
                </span>
            </div>
        </div>
    );
};

const ContactInfoPage = (props) => {
    const { data, user } = props;

    let providers = user.providers
        ? sortProvidersByState(user.providers)
        : sortProvidersByState(data.providers);

    //get unique states only
    providers = providers
        .filter((provider) => provider.state !== null)
        .reduce((b, i) => {
            if (b.filter((v) => v.state.name === i.state.name).length === 0) b.push(i);
            return b;
        }, []);

    return (
        <div style={{ marginTop: '15px' }}>
            <h2 className="dashboard-card__title-text">Contact Information</h2>
            <p style={{ fontStyle: 'italic', fontSize: '1.2rem', color: 'rgb(76, 76, 76)' }}>
                <strong>NOTE:</strong>Fields marked (<Required />) are required.
            </p>
            <div style={{ marginTop: '10px' }}>
                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Phone Number (Primary) <Required />
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="phone"
                        name="primary_phone_number"
                        placeholder=""
                        readOnly={!!user.phone_number}
                        normalize={normalizePhone}
                        required
                    />
                </span>

                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Email Address{' '}
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="email"
                        name="email_address"
                        placeholder=""
                        required
                    />
                </span>

                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        Home Address <Required />
                    </label>
                    <Field
                        component="input"
                        className="dashboard-form__input"
                        type="text"
                        name="home_address"
                        placeholder=""
                        required
                    />
                </span>

                <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                        State of Residence <Required />
                    </label>
                    <Field
                        component="select"
                        className="dashboard-form__input"
                        style={{ height: '4.2rem', background: '#fff' }}
                        name="home_address_state_id"
                        required
                    >
                        <option value="0">Please select a State</option>
                        {providers.map((provider) => (
                            <option key={provider.id} value={provider.state.id}>
                                {provider.state.name}
                            </option>
                        ))}
                    </Field>
                </span>
            </div>
        </div>
    );
};

const MedicalInfoPage = (props) => {
    const { user, selectedState } = props;
    let providers = [];
    if (user.providers) {
        providers = user.providers;
    } else {
        providers = [];
    }

    providers = providers
        .filter((provider) => provider.state !== null)
        .filter((provider) => provider.state.id === Number(selectedState));

    return (
        <div style={{ marginTop: '15px' }}>
            <h2 className="dashboard-card__title-text">Choose a Preferred Hospital</h2>
            <p style={{ fontStyle: 'italic', fontSize: '1.2rem', color: 'rgb(76, 76, 76)' }}>
                <strong>NOTE:</strong>Fields marked (<Required />) are required.
            </p>
            <div style={{ marginTop: '10px' }}>
                {providers && providers.length > 0 ? (
                    <span className="dashboard-form-item">
                        <p className="signup__body-text">You can change your hospital anytime.</p>
                        <Field
                            name="preferred_provider_id"
                            component={FilterableSelect}
                            props={{
                                items: providers,
                                placeholder: 'Search by name, address, location or state',
                                filterBy: ['name', 'address', 'state.name'],
                                extraKey: 'address'
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                fontSize: '1.2rem',
                                color: 'rgb(76, 76, 76)'
                            }}
                        >
                            Don't have a hospital yet? Click next to skip this step.
                        </p>
                    </span>
                ) : (
                    <div>
                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Preferred Hospital <Required />
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="text"
                                name="preferred_hospital_name"
                                placeholder=""
                                required
                            />
                        </span>

                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Hospital Address <Required />
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="text"
                                name="preferred_hospital_address"
                                placeholder=""
                                required
                            />
                        </span>
                    </div>
                )}

                <br />
            </div>
        </div>
    );
};

const ExtraInfoPage = (props) => {
    const { data, profilePicture, onImageSet, enrolleeType, isLoadingPhoto } = props;

    return (
        <div style={{ marginTop: '15px' }}>
            <h2 className="dashboard-card__title-text">Extra Information</h2>
            <p style={{ fontStyle: 'italic', fontSize: '1.2rem', color: 'rgb(76, 76, 76)' }}>
                <strong>NOTE:</strong>Fields marked (<Required />) are required.
            </p>
            <div style={{ marginTop: '10px' }}>
                {enrolleeType !== '3' && (
                    <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                            Marital Status
                        </label>
                        <Field
                            component="select"
                            className="dashboard-form__input"
                            style={{ height: '4.2rem', background: '#fff' }}
                            name="marital_status_id"
                        >
                            <option value={-1}>Please select a Marital Status</option>
                            {data.mStatuses.map((status) => (
                                <option key={status.id} value={status.id}>
                                    {status.name}
                                </option>
                            ))}
                        </Field>
                    </span>
                )}

                <Field component="file" type="hidden" name="profile_picture" />
                <Field component="input" type="hidden" name="profile_picture_filename" />

                {isLoadingPhoto ? (
                    <div style={{ width: '250px', height: '250px' }}>
                        <LoadingScreen />
                    </div>
                ) : (
                    <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                            Please add a recent Passport Photograph <Required />
                        </label>
                        <ImageInput
                            width={250}
                            height={250}
                            onCropComplete={onImageSet}
                            placeholder={profilePicture}
                        />
                    </span>
                )}

                <Field component="input" type="hidden" name="enrollee_type" />
                <Field component="input" type="hidden" name="hash" />
                <Field component="input" type="hidden" name="access_token" />

                <span className="dashboard-form-item">
                    <label htmlFor="" className="profile-item__label">
                        Does the Enrollee own a Smart-phone (Android, iOS etc)
                    </label>
                    <span className="dashboard-form__checklist-wrap">
                        <span className="dashboard-form-item__radio-check-btn">
                            <Field
                                style={{ zIndex: 0 }}
                                name="has_smartphone"
                                component="input"
                                type="radio"
                                value="1"
                                id="yes"
                            />
                            <label style={{ zIndex: 0 }} htmlFor="yes">
                                Yes
                            </label>
                        </span>
                        <span className="dashboard-form-item__radio-check-btn">
                            <Field
                                style={{ zIndex: 0 }}
                                name="has_smartphone"
                                component="input"
                                type="radio"
                                value="0"
                                id="no"
                            />
                            <label style={{ zIndex: 0 }} htmlFor="no">
                                No
                            </label>
                        </span>
                    </span>
                </span>
            </div>
        </div>
    );
};

const PageIndicator = (props) => {
    return (
        <div
            style={{
                backgroundColor: props.isActive ? '#094063' : '#e6e5e5',
                height: '5px',
                borderRadius: '3px',
                width: '100%',
                margin: '4px'
            }}
        >
            {' '}
        </div>
    );
};

const normalizePhone = (value) => {
    if (!value) {
        return value;
    }
    return value.replace(/[^\d]/g, '');
};

const sortProvidersByState = (uProviders) => {
    let providers = uProviders;
    for (let i = 0; i < providers.length; i++) {
        for (let j = 0; j < providers.length - 1 - i; j++) {
            if (providers[j].state) {
                if (providers[j].state.name > providers[j + 1].state.name) {
                    let temp = providers[j + 1];
                    providers[j + 1] = providers[j];
                    providers[j] = temp;
                }
            }
        }
    }
    return providers;
};

SignUpForm = reduxForm({
    form: 'signUpForm',
    destroyOnUnmount: false
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('signUpForm');
SignUpForm = connect((state) => {
    // can select values individually
    const selectedState = selector(state, 'home_address_state_id');
    const dateOfBirth = selector(state, 'date_of_birth');
    const profilePicture = selector(state, 'profile_picture');
    const preferredHospitalLocation = selector(state, 'preferred_hospital_location');
    return {
        selectedState,
        dateOfBirth,
        profilePicture,
        preferredHospitalLocation
    };
})(SignUpForm);

export default SignUpForm;
