import React from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router';
import FormHolder, { MessageHolder } from './boxes/FormHolder';
import ResetPasswordForm from './forms/ResetPasswordForm';
import ResetPasswordVerify from '../containers/ResetPasswordVerify';
import SecurityQuestionContainer from '../containers/SecurityQuestionContainer';
import userService from '../services/userService';
import LoadingScreen from './LoadingScreen';

const ResetPasswordComponent = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}/security`} component={SecurityQuestionContainer}/>
    <Route exact path={`${match.url}/new`} component={ResetPassword}/>
    <Route exact path={`${match.url}/verify`} component={ResetPasswordVerify}/>
    <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/verify`}/>}/>
  </Switch>
)

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const { state } = props.location;
    this.state = {
      code: state ? state.code : null,
      redirectUrl: null,
      loading: false,
      success: null
    }
  }

  submitNewPassword = (values) => {
    const confirmed = this.checkPassword(values);
    if (confirmed) {
      const payload = {
        ...values,
        code: this.state.code
      }
      this.setState({loading: true})
      userService.resetPasswordNew(payload)
        .then(data => this.setState({
          redirectUrl: data.redirect_url || window.location.origin,
          loading: false,
          success: true
        }))
        .catch(e => this.setState({
          success: false,
          loading: false,
          error: e
        }))
    } else {
      this.setState({error: { message: "The passwords you entered do not match" }})
    }
  }

  renderSuccessFulPage() {
    setTimeout(() => window.location.replace(this.state.redirectUrl), 5000);

    return (
      <FormHolder>
        <MessageHolder>
          <h2 style={{margin: "10px 0"}}>Password Reset Successful</h2>
          <p>Your password reset was successful, you'll be directed to the login page in a few moments.</p>
        </MessageHolder>
      </FormHolder>
    )
  }

  checkPassword(values) {
    return values.password === values.confirm_password;
  }

  render() {
    const { code, success, loading, error } = this.state;

    if (!code) return <Redirect to="/reset-password/security"/>

    if (loading) return <LoadingScreen />

    if (success) return this.renderSuccessFulPage();

    return (
      <FormHolder message={error && error.message}>
        <ResetPasswordForm 
          onSubmit={this.submitNewPassword}/>
      </FormHolder>
    )
  }
}

export default withRouter(ResetPasswordComponent);
