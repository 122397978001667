import React from 'react';
import {reduxForm, Field} from 'redux-form';

let ForgotPasswordForm = (props) => {

    const {handleSubmit,pristine,submitting} = props;

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <Field component="input" type="text" name="username" placeholder="Your HMO ID or Email Address" required/>
                <span className="dashboard-form-item">
                  <label id="reset_method_label" htmlFor="" className="profile-item__label" aria-label="Password reset method"/>
                  <span className="dashboard-form__checklist-wrap" aria-labelledby="reset_method_label">
                    <span className="dashboard-form-item__radio-check-btn">
                      <Field name="type" component="input" type="radio" value="1" id="email"/>
                      <label htmlFor="email">Reset Password by Email</label>
                    </span>
                    <span className="dashboard-form-item__radio-check-btn">
                      <Field name="type" component="input" type="radio" value="2" id="phone"/>
                      <label htmlFor="phone">Reset Password by Phone Number</label>
                    </span>
                  </span>
                </span>
                <input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value="Reset Password" />
            </form>
        </div>
    )
};


ForgotPasswordForm = reduxForm({
    form:'forgotPasswordForm'
})(ForgotPasswordForm);

export default ForgotPasswordForm