import React from 'react';
import {reduxForm, Field} from 'redux-form';

let ResetPasswordForm = (props) => {
	const {handleSubmit,pristine,submitting} = props;
	return (
		<div className="form" style={{height: "50%"}}>
			<h3 style={{"margin": "10px 0"}}>
				Kindly Enter Your New Password
			</h3>
			<form onSubmit={handleSubmit}>
        <label htmlFor="password" style={{display: "flex"}}>New Password</label>
				<Field component="input" id="password" type="password" minLength="6" name="password" placeholder="000000" style={{marginTop: "5px"}} required/>
        <label htmlFor="confirm_password" style={{display: "flex"}}>Confirm Password</label>
        <Field component="input" id="confirm_password" type="password" minLength="6" name="confirm_password" placeholder="000000" style={{marginTop: "5px"}} required/>
				<input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value="Submit" />
			</form>
		</div>
	)
};


ResetPasswordForm = reduxForm({
    form:'resetPasswordForm'
})(ResetPasswordForm);

export default ResetPasswordForm;
