import React from 'react';
import {Redirect} from 'react-router';
import userService from '../services/userService';
import LoadingScreen from '../components/LoadingScreen';
import FormHolder from '../components/boxes/FormHolder';
import SecurityQuestionForm  from '../components/forms/SecurityQuestionForm';

class SecurityQuestionContainer extends React.Component {
  constructor(props) {
    super(props);
    const { state } = props.location
    this.state = {
      loading: true,
      code: state ? state.code : null,
      data: null,
      error: null,
      success: null
    }
  }

  componentDidMount() {
    const { code } = this.state
    userService.getSecurityQuestion({code})
      .then(data => this.setState({
        loading: false,
        data
      }))
      .catch(e => this.setState({
        loading: false,
        error: e
      }))
  }

  handleSubmit = (values) => {
    const {data, code} = this.state;
    const payload = {
      ...values,
      code,
      question_id: data ? data.id : null,
    }
    this.setState({loading: true})
    userService.verifySecurityQuestion(payload)
      .then(() => this.setState({
        success: true,
        loading: false
      }))
      .catch(e => this.setState({
        success: false,
        loading: false,
        error: e
      }))
  }

  render() {
    const { loading, data, error, success, code } = this.state;

    if (!code) return <Redirect to="/login?fp=1"/>

    if (loading) return <LoadingScreen />

    if (success) return (
      <Redirect 
        to={{
          pathname: "/reset-password/new",
          state: { code }
        }}
      />
    )

    return (
      <FormHolder message={error && error.message}>
        <SecurityQuestionForm
          securityQuestion={data}
          onSubmit={this.handleSubmit}/>
      </FormHolder>
    )
  }
}

export default SecurityQuestionContainer;