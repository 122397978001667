import React from 'react';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import { features } from '../services/daara-config.json';

const InvoiceModalListComponent = ({ invoices }) => {
    return invoices.length > 0 ? (
        <table className="dashboard__table">
            <thead>
                <tr>
                    <th>Invoice Code</th>
                    <th>Available Slots</th>
                    <th>Amount (NGN)</th>
                    <th>Date Issued</th>
                </tr>
            </thead>
            <tbody>
                {features.invoiceService.list
                    ? invoices.map((invoice) => (
                          <tr key={invoice.referenceCode}>
                              <td>
                                  {invoice.availableSlot > 0 ? (
                                      <a
                                          href={`/dashboard/invoices/edit/add-enrollee?refCode=${invoice.referenceCode}`}
                                      >
                                          {invoice.referenceCode}
                                      </a>
                                  ) : (
                                      invoice.referenceCode
                                  )}
                              </td>
                              <td>{invoice.availableSlot}</td>
                              <td>{numberFormatter('#,###.00', invoice.price)}</td>
                              <td>{moment(invoice.issue_time).format('dddd, MMMM Do YYYY')}</td>
                          </tr>
                      ))
                    : invoices.map((invoice) => (
                          <tr key={invoice.reference_code}>
                              <td>
                                  {invoice.no_of_available_slots > 0 ? (
                                      <a
                                          href={`/dashboard/invoices/edit/add-enrollee?refCode=${invoice.reference_code}`}
                                      >
                                          {invoice.reference_code}
                                      </a>
                                  ) : (
                                      invoice.reference_code
                                  )}
                              </td>
                              <td>{invoice.no_of_available_slots}</td>
                              <td>{numberFormatter('#,###.00', invoice.total_price)}</td>
                              <td>{moment(invoice.issue_time).format('dddd, MMMM Do YYYY')}</td>
                          </tr>
                      ))}
            </tbody>
        </table>
    ) : (
        <p style={{ fontSize: '1.6rem', maxWidth: '500px', lineHeight: '2.4rem' }}>
            You have used up all the available employee slots in your invoice. To add more
            employee's, please contact support at{' '}
            <a href="mailto:hello@reliancehmo.com?subject=New Invoice">hello@reliancehmo.com</a>, so
            a new invoice can be generated for you. Thank You.
        </p>
    );
};

InvoiceModalListComponent.propTypes = {
    invoices: PropTypes.array
};

export default InvoiceModalListComponent;
