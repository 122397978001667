import React from 'react';
import * as _ from 'lodash';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import userService from '../services/userService';
import utilityService from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import NotificationModal from './modals/NotificationModal';
import OrderSummaryModal from './modals/OrderSummaryModal';
import { ItemContextSwitcher } from './ItemContextSwitcher';
import { features } from '../services/daara-config.json';

const PAYMENT_FREQUENCIES = [
    { id: 1, name: 'monthly' },
    { id: 2, name: 'quarterly' },
    { id: 3, name: 'yearly' }
];

class NewInvoiceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            providers: [],
            providersCountData: {},
            paymentFrequency: PAYMENT_FREQUENCIES[0],
            selectedPlans: [],
            showCheckoutModal: false,
            paymentSuccessfulModal: null,
            isLoading: false,
            isAllowed: true,
            error: null
        };
    }

    componentDidMount() {
        //get plans
        //set plans state object
        this.setLoading(true);

        utilityService.getPlans().then((response) => {
            this.setState({
                plans: response
            });

            const productIds = response
                .map((plan) => {
                    return plan.id;
                })
                .join();

            utilityService.getProviders(productIds).then((response) => {
                this.setState({
                    providers: response
                });
                this.getProductCount();
                this.setLoading(false);
            });
        });
    }

    getProductCount = () => {
        const { providers, plans } = this.state;
        const allProviders = providers;
        const allPlans = plans;

        let providersCountData = {};

        allPlans.forEach((plan) => {
            let scopedProviders = allProviders.filter((provider) => {
                return provider.products.indexOf(plan.id.toString()) !== -1;
            });

            providersCountData[plan.id] = scopedProviders.length;
        });

        this.setState({
            providersCountData
        });
    };

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    };

    setPaymentFrequency = (selectedFreq) => {
        const oldSP = this.state.selectedPlans;
        const plans = this.state.plans;

        this.setState({
            paymentFrequency: selectedFreq,
            selectedPlans:
                oldSP.length > 0
                    ? oldSP.map((sp) => {
                          return {
                              id: sp.id,
                              name: sp.name,
                              price: (sp.price = plans.filter((p) => p.name === sp.name)[0].price[
                                  selectedFreq.name
                              ]),
                              quantity: sp.quantity
                          };
                      })
                    : []
        });
    };

    onAddToPlanList = (planId, planName, planPrice, quantity) => {
        const plan = this.state.selectedPlans.filter((plan) => plan.id === planId)[0] || null;
        if (!plan) {
            this.setState({
                selectedPlans: [
                    ...this.state.selectedPlans,
                    { id: planId, name: planName, price: planPrice, quantity: quantity }
                ]
            });
        } else {
            const oldSP = this.state.selectedPlans;
            this.setState({
                selectedPlans: oldSP.map((p) =>
                    p.id === plan.id
                        ? {
                              id: planId,
                              name: planName,
                              price: planPrice,
                              quantity: quantity
                          }
                        : p
                )
            });
        }
    };

    onRemoveFromPlanList = (id) => {
        const oldSP = this.state.selectedPlans;
        _.remove(oldSP, (plan) => plan.id === id);
        this.setState({
            selectedPlans: oldSP
        });
    };

    onRemoveAllPlans = () => {
        this.setState({
            selectedPlans: []
        });
    };

    toggleCheckoutModal = () => {
        this.setState({
            showCheckoutModal: !this.state.showCheckoutModal
        });
    };

    goToAddEnrollee = (url) => {
        const { history } = this.props;
        setTimeout(() => {
            history.push(url);
        }, 1000);
    };

    onCheckout = (json) => {
        json.meta = {
            payment_frequency: this.state.paymentFrequency.name[0],
            for: 'others'
        };

        this.toggleCheckoutModal();

        if (features.enrolleeService.purchase) {
            const {
                user: { clientId }
            } = this.props;
            json = { ...json, clientId };
        }

        this.setLoading(true);
        userService.buyPlansAndExtensions(json).then(
            (response) => {
                if (features.enrolleeService.purchase) {
                    this.setLoading(false);
                    const {
                        data: { authorizationUrl, invoiceReference, useNewCard }
                    } = response.data;

                    window.location.href = authorizationUrl;
                } else {
                    if (!response.use_new_card) {
                        this.setState({
                            isLoading: false,
                            paymentSuccessfulModal: {
                                showModal: true,
                                redirectUrl:
                                    '/dashboard/invoices/edit/add-enrollee?refCode=' +
                                    response.invoice_reference_code
                            }
                        });
                    } else {
                        window.location.href = response.authorization_url;
                    }
                }
            },
            (e) => {
                this.setLoading(false);
                this.setState({
                    error: {
                        message: e.response.data ? e.response.data.message : 'An Error Occurred.'
                    }
                });
            }
        );
    };

    redirectToDashboard = () => {
        const { history } = this.props;
        history.push('/dashboard');
    };

    dismissErrorModal = () => {
        this.setState({
            error: null
        });
    };

    render() {
        const {
            selectedPlans,
            providersCountData,
            paymentFrequency,
            plans,
            showCheckoutModal,
            isLoading,
            isAllowed,
            error,
            paymentSuccessfulModal
        } = this.state;

        if (!isAllowed)
            return (
                <MainContent>
                    <NotificationModal
                        message={`Sorry! But you're not allowed to carry out this operation.
                                        If you need to purchase new plans, please contact our support via hello@reliancehmo.com
                                        or call 070073542623. Thank You.`}
                        onClose={this.redirectToDashboard}
                        open={true}
                    />
                </MainContent>
            );

        if (isLoading)
            return (
                <MainContent>
                    <LoadingScreen />
                </MainContent>
            );

        if (paymentSuccessfulModal && paymentSuccessfulModal.showModal)
            return (
                <MainContent>
                    <NotificationModal
                        message={
                            'Payment successful. Please go ahead and provision the plans you purchased to their respective parties.'
                        }
                        onClose={() => this.goToAddEnrollee(paymentSuccessfulModal.redirectUrl)}
                        open={true}
                    />
                </MainContent>
            );

        if (!isLoading) {
            return (
                <MainContent>
                    <OrderSummaryModal
                        plans={selectedPlans}
                        paymentFreq={paymentFrequency.name}
                        onCheckout={this.onCheckout}
                        onClose={this.toggleCheckoutModal}
                        open={showCheckoutModal}
                    />

                    <NotificationModal
                        message={error && error.message}
                        onClose={this.dismissErrorModal}
                        open={error || false}
                    />

                    <PageHeader title="Buy New Plans" />
                    <PageBody>
                        {selectedPlans.length > 0 && (
                            <CheckOutHeader
                                selectedPlans={selectedPlans}
                                onRemoveFromPlanList={this.onRemoveFromPlanList}
                                onRemoveAllPlans={this.onRemoveAllPlans}
                                onCheckout={this.toggleCheckoutModal}
                            />
                        )}

                        <div className="dashboard-card">
                            <p className="dashboard-card__body-text">
                                Select as many plans as you want, as well as a comfortable payment
                                plan. <br />
                                Please note that next payment(s) will be debited automatically from
                                your card.
                            </p>

                            <div className="plan-pricing-wrapper">
                                <ItemContextSwitcher
                                    items={PAYMENT_FREQUENCIES}
                                    selectedItem={paymentFrequency}
                                    onItemSelected={this.setPaymentFrequency}
                                />

                                <div className="plan-item-wrap">
                                    {plans.map((plan) => {
                                        return (
                                            <PlanComponent
                                                key={plan.id}
                                                planId={plan.id}
                                                planName={plan.name}
                                                providersCountData={providersCountData}
                                                planPrice={plan.price[paymentFrequency.name]}
                                                frequency={paymentFrequency.name.substr(
                                                    0,
                                                    paymentFrequency.name.length - 2
                                                )}
                                                onAddPlan={this.onAddToPlanList}
                                                isAdded={
                                                    selectedPlans.filter((p) => p.id === plan.id)
                                                        .length > 0
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </PageBody>
                </MainContent>
            );
        }
    }
}

class CheckOutHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDropDown: false
        };
    }

    toggleDropDown = () => {
        this.setState({
            openDropDown: !this.state.openDropDown
        });
    };

    render() {
        const { selectedPlans, onRemoveFromPlanList, onRemoveAllPlans, onCheckout } = this.props;

        return (
            <div className="dashboard-cart-container">
                <div className="dashboard-cart-header">
                    <div onClick={this.toggleDropDown}>
                        <h4 className="dashboard-card__title-text dashboard-cart-header__title-text">
                            {`  ${selectedPlans.reduce(
                                (sum, plan) => sum + plan.quantity,
                                0
                            )} Items in Cart`}
                        </h4>
                        <br />
                        <p className="dashboard-card__body-text">(Click to show/hide cart items)</p>
                    </div>
                    <button
                        className="dashboard__primary-btn dashboard-control__button"
                        onClick={onCheckout}
                    >
                        Click to Proceed
                    </button>
                </div>
                <div
                    className="dashboard-cart-body"
                    style={{ display: this.state.openDropDown ? 'block' : 'none' }}
                >
                    {selectedPlans.map((plan) => {
                        return (
                            <div key={plan.id} className="dashboard-cart-body__list-group">
                                <ul className="dashboard-cart-body__list">
                                    <li>
                                        <div className="dashboard-cart-body__list-item">
                                            <p className="dashboard-cart-body__text">{`${plan.name} x ${plan.quantity}`}</p>
                                            <button
                                                className="dashboard-cart__ghost-button"
                                                onClick={() => onRemoveFromPlanList(plan.id)}
                                            >
                                                Remove&nbsp;<i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                    <hr />
                    <div className="dashboard-cart-clear">
                        <button
                            className="dashboard__secondary-btn__danger"
                            onClick={onRemoveAllPlans}
                        >
                            REMOVE ALL
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

class PlanComponent extends React.Component {
    //name,price,id,frequency,onAdd

    constructor(props) {
        super(props);
        this.state = {
            quantity: 0,
            isAdded: false
        };
    }

    handleQuantityForm = (e) => {
        e.preventDefault();
        const { planId, planName, planPrice, onAddPlan } = this.props;
        const quantity = Number(e.target.quantity.value);
        if (quantity > 0) {
            onAddPlan(planId, planName, planPrice, quantity);
            e.target.reset();

            this.setState({
                isAdded: true
            });
            setTimeout(() => {
                this.setState({
                    isAdded: false
                });
            }, 1000);
        }
    };

    checkPlanName = () => {
        const { planName, planId, providersCountData } = this.props;

        if (planName.toLowerCase().indexOf('red beryl') > -1) {
            return {
                providersCount: providersCountData[planId],
                coverageAmount: '₦1.2 Million'
            };
        }
        if (planName.toLowerCase().indexOf('alexandrite') > -1) {
            return {
                providersCount: providersCountData[planId],
                coverageAmount: '₦1.8 Million'
            };
        }
    };

    render() {
        const { planName, planPrice, frequency } = this.props;

        return (
            <div className="plan-item ">
                <h4 className="dashboard-card__title-text  plan-item_rb">{planName}</h4>
                <div className="price-wrap">
                    <p className="plan-pricetag">
                        &#8358; {numberFormatter('#,###.00', planPrice)}
                    </p>
                    <h6 className="pricetag-duration">{`per ${frequency}`}</h6>
                </div>
                <p className="dashboard-card__body-text">
                    <span className="provider-count redBeryl">
                        Access to {this.checkPlanName.providersCount} hospitals
                    </span>
                    , Coverage for Medical Expenses up to {this.checkPlanName.coverageAmount} per
                    year,Eyecare up to &#8358;15,000...
                </p>
                <a className="plan-link" href="https://reliancehmo.com/benefits.html">
                    View comprehensive list of health benefits
                </a>
                <form onSubmit={this.handleQuantityForm} style={{ marginBottom: '15px' }}>
                    <div className="dashboard-input-wrap">
                        <input
                            className="dashboard-form__input"
                            name="quantity"
                            type="number"
                            min="1"
                            required
                            placeholder="Quantity (e.g 5)"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <button type="submit" className="dashboard__secondary-btn plan-btn">
                            Add to plan list
                        </button>
                    </div>
                </form>
                <p className="dashboard-card__body-text">
                    {this.state.isAdded ? 'Plan added successfully' : ''}
                </p>
            </div>
        );
    }
}

PlanComponent.propTypes = {
    planId: PropTypes.number,
    planName: PropTypes.string,
    planPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    frequency: PropTypes.string,
    onAddPlan: PropTypes.func,
    isAdded: PropTypes.bool
};

CheckOutHeader.propTypes = {
    selectedPlans: PropTypes.array,
    onCheckOut: PropTypes.func,
    onRemoveFromPlanList: PropTypes.func
};

export default NewInvoiceComponent;
