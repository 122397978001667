import React from 'react';
import moment from 'moment';
import {reduxForm, Field, change} from 'redux-form';
import DateTime from 'react-datetime';

let SecurityQuestionForm = (props) => {
  const { securityQuestion, handleSubmit, pristine, submitting } = props;
  
  const onDateSet = (date) => {
    const { dispatch } = props;
    const formattedDate = moment(date).format("DD-MM-YYYY");
    dispatch(change('securityQuestionForm', 'answer', formattedDate));
  };

  const isDOB = securityQuestion && securityQuestion.id === QUESTIONS.dateOfBirth;

	return (
		<div className="form" style={{height: "50%"}}>
			<h3 style={{"margin": "10px 0"}}>
				We will try to verify your identity by asking you a secret question
			</h3>
			<form onSubmit={handleSubmit}>
        {securityQuestion &&  <label htmlFor="security">{securityQuestion.question}</label>}
        <Field component="input" id="security" type={isDOB ? "hidden" : "text"} name="answer" placeholder={isDOB ? "" : "Enter your answer here..."} required/>
        { isDOB && 
            <DateTime
              closeOnSelect
              viewMode="years"
              timeFormat={false}
              dateFormat="DD-MM-YYYY"
              inputProps={{className:'dashboard-form__input', required: true, placeholder: "DD-MM-YYYY", readOnly: true}}
              onChange={onDateSet}
              isValidDate={(curr) => curr.year() <= DateTime.moment().year()}/>
        }
				<input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value="Submit" />
			</form>
		</div>
	)
};

const QUESTIONS = {
  dateOfBirth: 1,
  phoneNumber: 2
}

SecurityQuestionForm = reduxForm({
    form:'securityQuestionForm'
})(SecurityQuestionForm);

export default SecurityQuestionForm;