import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import * as invoiceActions from '../actions/invoice';
import InvoiceListComponent from '../components/lists/InvoiceListComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import { features } from '../services/daara-config.json';

class InvoiceListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: []
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        if (features.invoiceService.list) {
            const {
                user: { clientId }
            } = this.props;
            dispatch(invoiceActions.getUserInvoices(clientId));
        } else {
            dispatch(invoiceActions.getUserInvoices());
        }
    }

    renderLoading() {
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    render() {
        const { isFetching, invoices } = this.props;

        if (isFetching) return this.renderLoading();

        return (
            <MainContent>
                <PageHeader title="Billing History" />
                <PageBody>
                    <InvoiceListComponent invoices={invoices} />
                </PageBody>
            </MainContent>
        );
    }
}

const mapStateToProps = ({ invoice }) => ({
    isFetching: invoice.isFetching,
    invoices: invoice.invoices ? Immutable.asMutable(invoice.invoices) : {},
    error: invoice.error ? Immutable.asMutable(invoice.error) : {},
    alert: invoice.alert
});

const daaraMapStateToProps = ({ invoice, client }) => ({
    client: client.client,
    isFetching: invoice.isFetching,
    invoices: invoice.invoices ? Immutable.asMutable(invoice.invoices) : {},
    error: invoice.error ? Immutable.asMutable(invoice.error) : {},
    alert: invoice.alert
});

export default features.invoiceService.list
    ? withRouter(connect(daaraMapStateToProps)(InvoiceListContainer))
    : withRouter(connect(mapStateToProps)(InvoiceListContainer));
