import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import Immutable from 'seamless-immutable';
import utilityService from '../services/utilityService';
import userService from '../services/userService';
import * as enrolleeActions from '../actions/enrollee';
import NotificationBanner from '../components/boxes/NotificationBanner';
import EnrolleeDetailComponent from '../components/EnrolleeDetailComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import PageNotFound from '../components/PageNotFoundComponent';
import EditEnrolleeModal from "../components/modals/EditEnrolleeModal";
import EnrolleeExtensionsListComponent from "../components/lists/EnrolleeExtensionsListComponent";
import ExtensionSelectorModal from "../components/modals/ExtensionSelectorModal";
import DecisionModal from "../components/modals/DecisionModal";
import OptionsModal from "../components/modals/OptionsModal";
import axios from 'axios';
import SuccessImage from '../img/successful.svg'
import { BASE_URL_RELIANCE, DAARA_ENROLLEE_SERVICE_URL, DOMAIN_COOKIE_NAME, getCookie, DAARA_TOKEN_NAME } from '../services/utilityService';
import { features } from '../services/daara-config.json';

const BASE_URL = BASE_URL_RELIANCE

class EnrolleeDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openUpdateProfileModal: false,
      openExtensionSelectorModal: false,
      openRemoveEnrolleeModal: false,
      openTransferEnrolleeModal: false,
      openDeactivationProcessModal: false,
      openwrongDetailsModal: false,
      successfulDeactivation: false,
      openRemoveEnrolleeExtensionModal: false,
      selectedExtension: null,
      completeProfile: false,
      plans: null,
      selectedOption: '',
      isLoading: false,
      openEditProfileModal: false,
      openReactivationModal: false,
      successfulReactivation: false,
      successfullyUpdated: false,
      message: null, // To trigger nnotification banner
      status:null, // To trigger nnotification banner
    }
    this.accessToken = getCookie(features.accountService.login ? DAARA_TOKEN_NAME : DOMAIN_COOKIE_NAME);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(enrolleeActions.getUserEnrolleeById(match.params.enrolleeId));
    utilityService.getPlans()
      .then(plans => {
        this.setState({
          plans
        })
      })
  }

  toggleEnrolleeModal = () => {
    this.setState({
      openUpdateProfileModal: !this.state.openUpdateProfileModal,
    })
  };

  toggleExtensionSelectorModal = () => {
    this.setState({
      openExtensionSelectorModal: !this.state.openExtensionSelectorModal
    })
  };

  completeEnrolleeProfile = () => {
    this.setState({
      completeProfile: true
    })
  };

  onTransferEnrollee = () => {
    const { dispatch, match, history } = this.props;
    dispatch(enrolleeActions.transferUserEnrollee(match.params.enrolleeId, history));
    this.toggleTransferEnrolleeModal();
  };

  onRemoveEnrollee = () => {
    const { dispatch, match, history } = this.props;
    dispatch(enrolleeActions.removeUserEnrollee(match.params.enrolleeId, history));
    this.toggleRemoveEnrolleeExtensionModal();
  };

  toggleTransferEnrolleeModal = () => {
    this.setState({
      openTransferEnrolleeModal: !this.state.openTransferEnrolleeModal
    });
  };

  toggleDeactivationProcess = () => {
    this.setState({
      openDeactivationProcessModal: !this.state.openDeactivationProcessModal
    })
  }

  // edit profile 
  toggleEditProfileModal = (obj) => {
    this.setState({
      openEditProfileModal: !this.state.openEditProfileModal,
    })
    
    let first_name = obj.target.getAttribute('data-name');
    let last_name = obj.target.getAttribute('data-last');
    let phone_number = obj.target.getAttribute('data-phone');
    let email_address = obj.target.getAttribute('data-email');

    let delayTimer = setTimeout(function () {
      if (typeof first_name !== 'undefined' && first_name !== null) {
        document.getElementById("fName").value = first_name;
      } if (typeof last_name !== 'undefined' && last_name !== null) {
        document.getElementById("LName").value = last_name;
      } if (typeof phone_number !== 'undefined' && phone_number !== null) {
        document.getElementById("PNumbber").value = phone_number;
      } if (typeof email_address !== 'undefined' && email_address !== null) {
        document.getElementById("EAddress").value = email_address;
      } else { return null }

      clearTimeout(delayTimer)
    }, 500)
    
  }

  toggleRemoveEnrolleeModal = () => {
    this.setState({
      openRemoveEnrolleeModal: !this.state.openRemoveEnrolleeModal
    })
  };

  toggleRemoveEnrolleeExtensionModal = (selectedExtension) => {
    this.setState({
      openRemoveEnrolleeExtensionModal: !this.state.openRemoveEnrolleeExtensionModal,
      selectedExtension
    })
  };


  // Trigger wrong details information
  wrongNameInfo = (obj) => {
    this.setState({
      openwrongDetailsModal: !this.state.openwrongDetailsModal,
      openDeactivationProcessModal: !this.state.openDeactivationProcessModal,
    })
    let first_name = obj.target.getAttribute('data-name');
    let last_name = obj.target.getAttribute('data-last');
    let phone_number = obj.target.getAttribute('data-phone');
    let email_address = obj.target.getAttribute('data-email');

    let delayTimer = setTimeout(function () {
      if (typeof first_name !== 'undefined' && first_name !== null) {
        document.getElementById("fName").value = first_name;
      } if (typeof last_name !== 'undefined' && last_name !== null) {
        document.getElementById("LName").value = last_name;
      } if (typeof phone_number !== 'undefined' && phone_number !== null) {
        document.getElementById("PNumbber").value = phone_number;
      } if (typeof email_address !== 'undefined' && email_address !== null) {
        document.getElementById("EAddress").value = email_address;
      } else { return null }

      clearTimeout(delayTimer)
    }, 500)
  }

  // update successful
  toggleUpdatedModal = () => {
    this.setState({
      successfullyUpdated: !this.state.successfullyUpdated,

    })
    window.location.reload()
  }


  // Open reactivation modal
  toggleReactivationModal = () => {
    this.setState({
      openReactivationModal: !this.state.openReactivationModal
    })
  }

  chooseContent = (e) => {
    this.setState({
      selectedOption: e.currentTarget.value,
    })
  }

  //  Close deactivation modal
  toggleDeactivationModal = () => {
    const { dispatch, match, history } = this.props;
    dispatch(enrolleeActions.removeUserEnrollee(match.params.enrolleeId, history));
    this.setState({
      successfulDeactivation: !this.state.successfulDeactivation,
    })
  }

  RedirectToEnrolleList = () => {
    const {history } = this.props;
    history.push('/dashboard/enrollees')
  }

  ReactivationModalTrigger = () => {
    this.setState({
      successfulReactivation: !this.state.successfulReactivation,
    })
    window.location.replace('/dashboard/enrollees');
  }


  // save editted profile changes
  saveChangesButton = () => {
    const { match } = this.props
    let user_id = match.params.enrolleeId;
    let profile_first_name = document.getElementById("fName").value;
    let profile_last_name = document.getElementById("LName").value;
    let profile_number = document.getElementById("PNumbber").value;
    let profile_email = document.getElementById("EAddress").value;
    this.setState({
      isLoading: true
    })
    let url, data;
    if (features.enrolleeService.editProfile) {
      url = `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/dependant`;
      data = {
        id: user_id,
        firstName: profile_first_name,
        lastName: profile_last_name,
        phoneNumber: profile_number,
        email: profile_email
      }
    }else {
      url = `${BASE_URL}/enrollees/${user_id}/dependant`;
      data = {
        first_name: profile_first_name,
        last_name: profile_last_name,
        email_address: profile_email,
        phone_number: profile_number
      }
    }
    axios.patch(url, data, {headers: {"Authorization": `Bearer ${this.accessToken}`}})
    .then(res => {
      if (features.enrolleeService.editProfile) {
        res.status = res.status == 200 ? "success" : res.status;
      }
      if( res.status === 'success' ) {
        this.setState({
          successfullyUpdated: !this.state.successfullyUpdated,
          openEditProfileModal: !this.state.openEditProfileModal,
          isLoading: false
        })
      }
      else {
        return (
          this.setState({
            isLoading: false
          })
        )
      }
    }, this.setState({ isLoading: true }))
    .catch((error) => {
      let message;
      if (features.enrolleeService.editProfile) {
        message = error.response.data.data ? error.response.data.data.cause : error.response.data.detail;
      } else {
        message = error.response.data.message;
      }
      alert(message) // Fetch exact error message
      this.setState({ isLoading: false, openEditProfileModal: !this.state.openEditProfileModal})
    })
  }


  // submit reason trigger
  submitReason = () => {
    const { match } = this.props;
    let user_id = match.params.enrolleeId;
    let typed_reason = document.getElementById('typed_reason');
    const { selectedOption } = this.state;
    let reason = '';

    if ( selectedOption !== "" ) {
      reason = selectedOption === "Other reason" ? typed_reason.value : selectedOption
    }else {
        return alert("The reason field is required.");
    }
    let url, data, method;
    if (features.enrolleeService.deactivateAccount) {
      url = `${DAARA_ENROLLEE_SERVICE_URL}/enrollee-deactivation-reasons`
      data = {
        enrolleeId: user_id,
        status: 'DEACTIVATED',
        reason,
      }
    } else {
      url = `${BASE_URL}/enrollees/${user_id}/deactivate-reason`;
      data = {
        reason: reason
      }
    }

    axios.post(url, data, {headers: {"Authorization": `Bearer ${this.accessToken}`}})
    .then(res => {
      if (features.enrolleeService.deactivateAccount) {
        res.status = res.status == 201 ? 'success' : res.status;
      }
      if (res.status === 'success') {
        this.setState({
          successfulDeactivation: !this.state.successfulDeactivation,
          openDeactivationProcessModal: !this.state.openDeactivationProcessModal,
          isLoading: false
        })
      }
      else {
        return (
          this.setState({
            successfulDeactivation: !this.state.successfulDeactivation,
            isLoading: false
          })
        )
      }
    }, this.setState({ isLoading: true }))
    .catch((error) => {
      console.log(error);
      this.setState({ 
        isLoading: false ,
        openDeactivationProcessModal: !this.state.openDeactivationProcessModal,
        message: `Deactivation went wrong , ${error.message}`,
        status: "error"
      })
    })
  }

  onRemoveExtension = () => {
    const { dispatch, match } = this.props;
    dispatch(enrolleeActions.removeUserExtension(match.params.enrolleeId, this.state.selectedExtension));
    this.toggleRemoveEnrolleeExtensionModal();
  };

  onPurchaseExtension = ({ useNewCard, cart, frequency }) => {
    const { dispatch, user : { clientId } } = this.props;
    dispatch(enrolleeActions.setEnrolleeLoading(true));
    userService.buyPlansAndExtensions(
      {
        callback_url: "/payment-successful",
        clientId: clientId,
        cart,
        meta: {
          payment_frequency: frequency[0],
          for: "others"
        }
      })
      .then(response => {
        if (features.enrolleeService.addExtraBenefit) {
          const {
            data: { authorizationUrl }
          } = response.data;
          if (useNewCard) {
            window.location.href = authorizationUrl;
          } else { 
            window.location.reload(true);
          }
        }else {
          if(!response.use_new_card){
            window.location.reload(true);
          } else {
            window.location.href = response.authorization_url;
          }
        }

      })
      .catch(e => {
        console.log(e.message);
      })
  };

  renderLoading() {
    return (
      <MainContent>
        <PageBody>
          <LoadingScreen />
        </PageBody>
      </MainContent>
    )
  };

  onEditEnrollee = (values, dispatch) => {
    this.toggleEnrolleeModal();
    dispatch(enrolleeActions.editUserEnrollee(
      values.user_id,
      values
    ));
  };

  onPlanUpgrade = (newPlan) => {
    const { dispatch, match } = this.props;
    dispatch(enrolleeActions.editUserSubscription(
      match.params.enrolleeId,
      {
        product_id: newPlan.id
      }
    ))
  };

  // Reactivate deactivate profile trigger
  reactivateProfile = () => {
    const { match } = this.props;
    let user_id = match.params.enrolleeId;

    let url, data, method;
    if (features.enrolleeService.reactivateAccount) {
      url = `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${user_id}/reactivation`
      data = {
        id: user_id,
        status: 'ACTIVE'
      }
    } else {
      url = `${BASE_URL}/enrollees/${user_id}/reactivate`;
      data = {}
    }

    axios.patch(url, data, { headers: {"Authorization": `Bearer ${this.accessToken}`}})
    .then(res => {
      if ( res.status !== 'success' ) {
        this.setState({
          successfulReactivation: !this.state.successfulReactivation,
          openReactivationModal: !this.state.openReactivationModal,
          isLoading: false
        })
      } else {
        return (
          this.setState({
            successfulReactivation: !this.state.successfulReactivation,
            isLoading: false
          })
        )
      }
    }, this.setState({ isLoading: true }))
    .catch(e => {
      this.setState({
        status:"error",
        message: e.response.data.data ? e.response.data.data.cause : e.response.data.message,
      })
      setTimeout(() => {
        window.location.href = '/dashboard/enrollees'
      }, 2000)
    })
      this.setState({ isLoading: false })
  }

  returnToEnrolleeList = () => {
    window.location.replace('/dashboard/enrollees');
  }


  render() {

    const {
      openUpdateProfileModal,
      completeProfile,
      openExtensionSelectorModal,
      openTransferEnrolleeModal,
      openRemoveEnrolleeModal,
      openRemoveEnrolleeExtensionModal,
      openDeactivationProcessModal,
      openwrongDetailsModal,
      successfulDeactivation,
      isLoading,
      openEditProfileModal,
      openReactivationModal,
      successfulReactivation,
      successfullyUpdated,
      status, 
      message
    } = this.state;

    const { isFetching, selectedEnrollee, error, alert, user, match } = this.props;

    if (error && error.code === 404) return <PageNotFound />;

    if (completeProfile) {
      return <Redirect push to={`${match.url}/complete-profile?hash=${selectedEnrollee.hash}`} />
    }

    if (!isFetching && !_.isEmpty(selectedEnrollee))
      return (
        <MainContent>
                  {status && <NotificationBanner
          title={status === "error" ? "Error!" : "Notification!"}
          message={message}
          onDismiss={this.onDismiss}
          type={status === "error" ? "error" : "success"}/>}
          <React.Fragment>
            <EditEnrolleeModal
              onFormSubmit={this.onEditEnrollee}
              onClose={this.toggleEnrolleeModal}
              user={selectedEnrollee}
              accessToken={user.credentials.accessToken}
              open={openUpdateProfileModal && !isFetching} />

            <ExtensionSelectorModal
              onClose={this.toggleExtensionSelectorModal}
              planId={selectedEnrollee.product.id}
              planName={selectedEnrollee.product.name}
              userId={match.params.enrolleeId}
              planExpires={selectedEnrollee.cover_end_date}
              onComplete={this.onPurchaseExtension}
              exclude={!selectedEnrollee.extensions ? [] : selectedEnrollee.extensions.map(ext => ext.product.id)}
              open={openExtensionSelectorModal && !isFetching} />
          </React.Fragment>

          <React.Fragment>
            <DecisionModal
              title={`Transfer ${selectedEnrollee.first_name} ${selectedEnrollee.last_name}`}
              onYes={this.onTransferEnrollee}
              onClose={this.toggleTransferEnrolleeModal}
              isVisible={openTransferEnrolleeModal && !isFetching} />

            {/* Deactivation trigger */}
            <DecisionModal
              title={`Deactivate ${selectedEnrollee.first_name} ${selectedEnrollee.last_name}`}
              onYes={this.onRemoveEnrollee}
              onClose={this.toggleRemoveEnrolleeModal}
              content={"Please note, clicking 'Yes' will initiate the deactivation process. This process will be completed by the end of your billing cycle. During this period, the enrollee will still have access to care."}
              isVisible={openRemoveEnrolleeModal && !isFetching} />

              {/* edit profile section */}
              <OptionsModal
              title={`Which details will you like to change?`}
              onYes={this.onRemoveEnrollee}
              onClose={this.toggleEditProfileModal}
              isVisible={openEditProfileModal && !isFetching}>
              <div className="edit-options">
                <h5>Erase the existing details and replace with the correct information</h5>
                <form>
                  <div className="edit-form">
                    <div className="edit-form-section">
                      <div className="form-group push-bottom">
                        <label htmlFor="fName">First Name</label>
                        <input type="name" className="form-control" id="fName" placeholder="Enter First Name" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fName">Phone Number</label>
                        <input type="phone" className="form-control" id="PNumbber" placeholder="Enter Phone Number" />
                      </div>
                    </div>
                    <div className="edit-form-section">
                      <div className="form-group push-bottom">
                      <label htmlFor="fName">Last Name</label>
                        <input type="name" className="form-control" id="LName" placeholder="Enter Last Name" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fName">Email Address</label>
                        <input type="email" className="form-control" id="EAddress" placeholder="Email Address" />
                      </div>
                    </div>
                  </div>
                  <button type="button" 
                  onClick={this.saveChangesButton}>Save Changes</button>
                </form>
              </div>
            </OptionsModal>

            {/* Deactivation Process Option */}
            {isLoading && <LoadingScreen />}
              <OptionsModal
                title={`Are you sure you want to deactivate ${selectedEnrollee.first_name}  ${selectedEnrollee.last_name} ?`}
                onYes={this.onRemoveEnrollee}
                onClose={this.toggleDeactivationProcess}
                isVisible={openDeactivationProcessModal && !isFetching}>
                <div className="deactivation-options">
                  <h6>If you deactivate this account, you will not be charged for it when your next payment is due.
                   However, you can continue to enjoy your benefits till your invoice expires. You can reactivate 
                   your plan when you miss us <span role="img" aria-label="smiley">😊</span>.</h6>
                   <h6> Continue below to deactivate your account. Otherwise, click cancel</h6>
                   <h2>Please tell us why you want to deactivate</h2>
                  <form action="#">
                    {selectedEnrollee.profile === null ? 
                    ( <p>
                      <input type="radio" id="option1" 
                      data-id={selectedEnrollee.id}
                      data-name={selectedEnrollee.first_name}
                      data-last={selectedEnrollee.last_name}
                      data-phone={selectedEnrollee.phone_number}
                      data-email={selectedEnrollee.email_address}
                      onChange={this.wrongNameInfo} value="" name="radio-group" />
                      <label htmlFor="option1">I entered the wrong name, email or phone number and want to correct it</label>
                    </p> )
                    :
                    ( <p>
                      <input type="radio" id="option1" onChange={this.wrongNameInfo} value="" name="radio-group" />
                      <label htmlFor="option1">I entered the wrong name, email or phone number and want to correct it</label>
                    </p> )
                    }
                    <p>
                      <input type="radio" id="option2" 
                        onChange={this.chooseContent}
                        value="I am no longer interested in health insurance"
                        name="radio-group" />
                      <label htmlFor="option2">I am no longer interested in health insurance</label>
                    </p>
                    <p>
                      <input type="radio" id="option3" 
                        onChange={this.chooseContent}
                        value = "Other reason"
                        name="radio-group" />
                      <label htmlFor="option3">Other Reason</label>
                    </p>
                    { this.state.selectedOption === "Other reason" && <textarea rows="5" placeholder="Type reason" id="typed_reason"></textarea> }
                    <button type="button" 
                    onClick={this.submitReason}>Submit</button>
                  </form>
                </div>
              </OptionsModal>

            {/* Wrong details information */}
            {selectedEnrollee.profile === null ?
              ( <OptionsModal
              title={`How to edit profile details`}
              onYes={this.onRemoveEnrollee}
              onClose={this.wrongNameInfo}
              isVisible={openwrongDetailsModal && !isFetching}>
              <div className="edit-options">
                <h5>Erase the existing details and replace with the correct information</h5>
                <form>
                  <div className="edit-form">
                    <div className="edit-form-section">
                      <div className="form-group push-bottom">
                        <label htmlFor="fName">First Name</label>
                        <input type="name" className="form-control" id="fName" placeholder="Enter First Name" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fName">Phone Number</label>
                        <input type="phone" className="form-control" id="PNumbber" placeholder="Enter Phone Number" />
                      </div>
                    </div>
                    <div className="edit-form-section">
                      <div className="form-group push-bottom">
                      <label htmlFor="fName">Last Name</label>
                        <input type="name" className="form-control" id="LName" placeholder="Enter Last Name" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fName">Email Address</label>
                        <input type="email" className="form-control" id="EAddress" placeholder="Email Address" />
                      </div>
                    </div>
                  </div>
                  <button type="button" 
                  onClick={this.saveChangesButton}>Save Changes</button>
                </form>
              </div>
            </OptionsModal> )
              :
            ( <OptionsModal
              title={`How to edit profile details`}
              onYes={this.onRemoveEnrollee}
              onClose={this.wrongNameInfo}
              isVisible={openwrongDetailsModal && !isFetching}>
              <div className="wrong-infos">
                <p>If you accidentally entered the wrong details e.g name or email, do not deactivate your account</p>
                <p>To edit profile details, contact customer care on <span>070073542623</span> or <span>info@reliancehmo.com</span></p>
                <button type="button" onClick={this.RedirectToEnrolleList}>Okay</button>
              </div>
            </OptionsModal> )
            }
            

            {/* open reactivate modal */}
            <OptionsModal
              title={`Are you sure you want to reactivate ${selectedEnrollee.first_name} ${selectedEnrollee.last_name}?`}
              onYes={this.onRemoveEnrollee}
              onClose={this.toggleReactivationModal}
              isVisible={openReactivationModal && !isFetching}>
              <div className="wrong-infos">
                <p>By reactivating this account, you will continue using your plan 
                and you will be billed for it when your next payment is due.”</p>
                <div className="reactivate-btn">
                  <button type="button" 
                  onClick={this.reactivateProfile}>Yes, reactivate</button>
                  <button type="button" 
                  onClick={this.returnToEnrolleeList}>Return to Enrollee list</button>
                </div>
              </div>
            </OptionsModal>

              {/* successful reactivation */}
              <OptionsModal
              title={`Reactivation Successful`}
              onYes={this.onRemoveEnrollee}
              onClose={this.ReactivationModalTrigger}
              isVisible={successfulReactivation && !isFetching}>
              <div className="for-deactivate">
                <img src={SuccessImage} alt="" />
                <h1>Reactivation Successful</h1>
                <p>{`You have successfully re-activated ${selectedEnrollee.first_name} 
                ${selectedEnrollee.last_name}’s ${selectedEnrollee.product.name} plan.`}</p>
                <p>They can continue access to healthcare, You will be billed for this plan at your next payment period</p>
                <button type="button" onClick={this.ReactivationModalTrigger}>Return to Enrollee list</button>
              </div>
            </OptionsModal>

              {/* update successful */}
              <OptionsModal
              title={`Update Successful`}
              onYes={this.onRemoveEnrollee}
              onClose={this.toggleUpdatedModal}
              isVisible={successfullyUpdated && !isFetching}>
                <div className="for-deactivate">
                  <img src={SuccessImage} alt="" />
                  <h1>Updated Successfully</h1>
                  <p>Your profile details have been updated</p>
                  <button type="button" onClick={this.toggleUpdatedModal}>Okay</button>
                </div>
              </OptionsModal>

            {/* successful deactivation */}
            <OptionsModal
              title={`Deactivation Successful`}
              onYes={this.onRemoveEnrollee}
              onClose={this.toggleDeactivationModal}
              isVisible={successfulDeactivation && !isFetching}>
              <div className="for-deactivate">
                <img src={SuccessImage} alt="" />
                <h1>Deactivation Successful</h1>
                <p>You have successfully deactivated your plan.</p>
                <button type="button" onClick={this.toggleDeactivationModal}>Okay</button>
              </div>
            </OptionsModal>

            <DecisionModal
              title={`Remove Extension?`}
              onYes={this.onRemoveExtension}
              onClose={this.toggleRemoveEnrolleeExtensionModal}
              content={"Please note, clicking 'Yes' will initiate the removal process. This process will be completed by the end of your billing cycle."}
              isVisible={openRemoveEnrolleeExtensionModal && !isFetching} />
          </React.Fragment>

          <PageHeader title={`${selectedEnrollee.first_name} ${selectedEnrollee.last_name}'s Profile`} />
          <PageBody>
            {(!_.isEmpty(error) || alert) &&
              <NotificationBanner
                title={error.message ? "Error!" : "Notification!"}
                message={error.message ? error.message : alert}
                type={error.message ? "error" : "success"} />
            }

            <div className="dashboard-btn-section">
              {selectedEnrollee.profile === null &&
                <button
                  className="dashboard__secondary-btn edit-btn"
                  style={{ marginLeft: "auto" }}
                  data-id={selectedEnrollee.id}
                  data-name={selectedEnrollee.first_name}
                  data-last={selectedEnrollee.last_name}
                  data-phone={selectedEnrollee.phone_number}
                  data-email={selectedEnrollee.email_address}
                  onClick={this.toggleEditProfileModal}>
                  Edit Profile
                </button>
              }
              {selectedEnrollee.deactivated === true ?
                <button
                  className="dashboard__secondary-btn__green"
                  style={{ marginLeft: "auto" }}
                  onClick={this.toggleReactivationModal}>
                  Reactivate Enrollee
                  </button>
                :
                <>
                  <button
                    className="dashboard__secondary-btn transfer-btn"
                    onClick={this.toggleTransferEnrolleeModal}>
                    Transfer Ownership
                  </button>
                  <button
                    className="dashboard__secondary-btn__danger"
                    style={{ marginLeft: "auto" }}
                    onClick={this.toggleDeactivationProcess}>
                    Deactivate Enrollee
                  </button>
                </>
              }
            </div>

            <EnrolleeDetailComponent
              selectedEnrollee={selectedEnrollee}
              toggleEnrolleeModal={this.toggleEnrolleeModal}
              completeEnrolleeProfile={this.completeEnrolleeProfile}
              onPlanUpgrade={this.onPlanUpgrade} />


            <div className="dashboard-card dashboard-table-wrap" style={{ marginTop: "2rem" }}>
              <div className="dashboard-table-overflow">

                <div className="dashboard-control">
                  <h2 className="dashboard-card__title-text" style={{ marginBottom: "0" }}>Extra
                            Benefits</h2>
                  <button className="dashboard__secondary-btn" onClick={this.toggleExtensionSelectorModal}>Add Extra Benefit</button>
                </div>

                {selectedEnrollee.extensions && selectedEnrollee.extensions.length > 0 ? (
                  <EnrolleeExtensionsListComponent extensions={selectedEnrollee.extensions}
                    onRemove={this.toggleRemoveEnrolleeExtensionModal} />
                ) : (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "150px" }}>
                    <h3 style={{ padding: "3rem 2rem", color: "#d6d6d6", fontSize: "3rem" }}>This Enrollee has no extra Benefits</h3>
                  </div>
                )
                }
              </div>
            </div>
          </PageBody>
        </MainContent>
      );
    return this.renderLoading();
  }
}


const mapStateToProps = ({ enrollees }) => ({
  selectedEnrollee: enrollees.selectedEnrollee ? Immutable.asMutable(enrollees.selectedEnrollee) : {},
  isFetching: enrollees.isFetching,
  error: enrollees.error ? Immutable.asMutable(enrollees.error) : {},
  alert: enrollees.alert,
  meta: enrollees.meta
});

export default connect(mapStateToProps)(EnrolleeDetailContainer);