import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import { features } from '../../services/daara-config.json';
import {
    decodePaymentFrequency,
    decodeResourceStatus,
    getPaymentFrequency
} from '../../services/utilityService';

const InvoiceListComponent = ({ invoices }) =>
    invoices.length > 0 ? (
        <div className="dashboard-card dashboard-table-wrap">
            <div className="dashboard-table-overflow">
                <table className="dashboard-table">
                    <thead>
                        <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Status
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Invoice Code
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Description
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Amount
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Payment Frequency
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Date Issued
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice) => (
                            <tr key={invoice.reference_code}>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {decodeResourceStatus(invoice.active_status_id)?.toUpperCase()}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    <a
                                        className="dashboard-table-item__link-item--underlined"
                                        href={`/dashboard/invoices/${invoice.reference_code}`}
                                    >
                                        {invoice.reference_code}
                                    </a>
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">{`Payment for ${
                                    invoice.plan_count ? invoice.plan_count + ' Plan(s)' : ''
                                }`}</td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    &#8358;{`${numberFormatter('#,###.00', invoice.total_price)}`}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {decodePaymentFrequency(invoice.payment_frequency_id)}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {moment(invoice.created_at).format('dddd, MMMM Do YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : (
        <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '3rem', color: '#ccc', margin: '10rem 5rem' }}>
                You have no invoices.
            </p>
        </div>
    );

const InvoiceList = ({ invoices }) =>
    invoices.length > 0 ? (
        <div className="dashboard-card dashboard-table-wrap">
            <div className="dashboard-table-overflow">
                <table className="dashboard-table">
                    <thead>
                        <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Status
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Invoice Code
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Description
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Amount
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Payment Frequency
                            </th>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Date Issued
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice) => (
                            <tr key={invoice.referenceCode}>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {invoice.activeStatus?.toUpperCase()}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    <a
                                        className="dashboard-table-item__link-item--underlined"
                                        href={`/dashboard/invoices/${invoice.referenceCode}`}
                                    >
                                        {invoice.referenceCode}
                                    </a>
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">{`Payment for ${
                                    invoice.invoiceItems
                                        ? invoice.invoiceItems.length + ' Plan(s)'
                                        : ''
                                }`}</td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    &#8358;{`${numberFormatter('#,###.00', invoice.price)}`}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {getPaymentFrequency(invoice.metadata)}
                                </td>
                                <td className="dashboard-table-item dashboard-table-item__body">
                                    {moment(invoice.createdDate).format('dddd, MMMM Do YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : (
        <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '3rem', color: '#ccc', margin: '10rem 5rem' }}>
                You have no invoices.
            </p>
        </div>
    );

const getInvoiceStatusIndicator = (paymentStatus, activeStatus) => {
    if (features.invoiceService.list) {
        if (activeStatus.toUpperCase() == 'SUSPENDED' || activeStatus.toUpperCase() == 'EXPIRED') {
            return 'Expired';
        }
        switch (paymentStatus) {
            case 'NO_PAYMENT':
                return 'Not Paid';
            case 'PARTIAL_PAYMENT':
                return 'Part Paid';
            default:
                return 'Payment Complete';
        }
    }
    if (activeStatus === 4 || activeStatus === 5) {
        return 'Expired';
    }

    switch (paymentStatus) {
        case 1:
            return 'Not Paid';
        case 2:
            return 'Part Paid';
        default:
            return 'Payment Complete';
    }
};

InvoiceListComponent.propTypes = {
    invoices: PropTypes.array
};

export default features.invoiceService.list ? InvoiceList : InvoiceListComponent;
