import React, {Component} from 'react';
import {Field} from "redux-form";
import PropTypes from 'prop-types';

export default class CheckboxGroup extends Component {

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired
    };

    field = ({input, meta, options}) => {

        const {name, onChange, onBlur, onFocus} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

        const checkboxes = options.map((opt, index) => {

            const handleChange = (event) => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    arr.push(opt.id);
                }
                else {
                    arr.splice(arr.indexOf(opt.id), 1);
                }
                onBlur(arr);
                return onChange(arr);
            };
            const checked = inputValue.includes(opt.id);
            return (
              <span key={`checkbox-${index}`} className="dashboard-form-item__radio-check-btn dashboard-form-item__check-btn">
                <input type="checkbox" name={`${name}[${index}]`} value={opt.id} checked={checked} onChange={handleChange} onFocus={onFocus}/>
                <label htmlFor={`${name}[${index}]`}>{opt.name}</label>
              </span>
            );
        });

        return (
            <>
              <span className="dashboard-form__checklist-wrap">
                {checkboxes}
              </span>
              {touched && error && <p className="error">{error}</p>}
            </>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field} />;
    }
}
