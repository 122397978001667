import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import USER_STUB from '../user_stub.jpeg';
import PlanUpgradeComponent from './PlanUpgradeComponent';

const EnrolleeDetailComponent = ({
    selectedEnrollee,
    toggleEnrolleeModal,
    completeEnrolleeProfile,
    onPlanUpgrade
}) => {
    return (
        <div className="dashboard-card profile-section">
            <img
                className="profile__img"
                src={selectedEnrollee.profile ? selectedEnrollee.profile.picture : USER_STUB}
                alt="Enrollee"
            />

            <div className="profile-detail-wrap__layout">
                <div className="profile-detail-wrap">
                    <div className="profile-item-style-2">
                        <div className="profile-item-wrapper">
                            <p className="profile-item__label">Full Name</p>
                            <p className="profile-item__text">{`${selectedEnrollee.first_name} ${selectedEnrollee.last_name}`}</p>
                        </div>
                        <div className="profile-item-wrapper">
                            <p className="profile-item__label">Plan</p>
                            <p className="profile-item__text">{selectedEnrollee.product.name}</p>
                        </div>
                    </div>

                    <div className="profile-item-style-2 ">
                        <div className="profile-item-wrapper">
                            {selectedEnrollee.profile ? (
                                <React.Fragment>
                                    <p className="profile-item__label">HMO ID</p>
                                    <p className="profile-item__text">
                                        {selectedEnrollee.profile.hmo_id}
                                    </p>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <p className="profile-item__label">{'Email Address'}</p>
                                    <p className="profile-item__text">{`${
                                        selectedEnrollee.email_address || 'N/A'
                                    }`}</p>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="profile-item-wrapper">
                            <p className="profile-item__label">Phone Number</p>
                            <p className="profile-item__text">{`${
                                selectedEnrollee.phone_number || 'N/A'
                            }`}</p>
                        </div>
                    </div>

                    <div className="profile-item-style-2">
                        <div className="profile-item-wrapper">
                            <p className="profile-item__label">Cover Starts</p>
                            <p className="profile-item__text">
                                {moment(selectedEnrollee.cover_start_date).format(
                                    'dddd, MMMM Do YYYY'
                                )}
                            </p>
                        </div>
                        <div className="profile-item-wrapper">
                            <p className="profile-item__label">Cover Ends</p>
                            <p className="profile-item__text">
                                {moment(selectedEnrollee.cover_end_date).format(
                                    'dddd, MMMM Do YYYY'
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                {!selectedEnrollee.profile ? (
                    <div>
                        {selectedEnrollee.hash && (
                            <>
                                {' '}
                                <button
                                    className="dashboard__secondary-btn__green"
                                    style={{ marginLeft: '2rem' }}
                                    onClick={completeEnrolleeProfile}
                                >
                                    COMPLETE PROFILE
                                </button>
                                <div style={{ color: '#7d7d7d', padding: '1rem' }}>
                                    <i>
                                        Click 'complete profile' to complete the Enrollee's profile
                                        on their behalf.
                                    </i>
                                </div>{' '}
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        {selectedEnrollee.new_product ? (
                            <p style={{ fontSize: '1.2rem', color: '' }}>
                                PLEASE NOTE: Your plan will be updated{' '}
                                {selectedEnrollee.new_product.name || ''} by your next renewal.
                            </p>
                        ) : (
                            <PlanUpgradeComponent
                                callback={onPlanUpgrade}
                                exclude={selectedEnrollee.product.id}
                                enrollee={selectedEnrollee}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

EnrolleeDetailComponent.propTypes = {
    selectedEnrollee: PropTypes.object,
    toggleEnrolleeModal: PropTypes.func,
    completeEnrolleeProfile: PropTypes.func,
    onPlanUpgrade: PropTypes.func
};

export default EnrolleeDetailComponent;
