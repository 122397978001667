import axios from 'axios';
import * as _ from 'lodash';
import utilityService, {
    BASE_URL_RELIANCE,
    DAARA_CLIENT_SERVICE_URL,
    DAARA_ENROLLEE_SERVICE_URL,
    DAARA_INVOICE_SERVICE_URL,
    DAARA_ACCOUNT_SERVICE_URL,
    DAARA_TOKEN_NAME,
    decodeResourceStatus,
    encodeResourceStatus,
    DOMAIN_COOKIE_NAME,
    getCookie,
    setCookie,
    DAARA_REFRESH_TOKEN_NAME
} from './utilityService';
import { features } from './daara-config.json';
import {
    refreshAccessToken,
    sendHttpGetRequest,
    sendHttpPostRequest,
    sendHttpPutRequest
} from './axiosInterceptor';
import { getDomain } from '../actions/user';

const BASE_URL = BASE_URL_RELIANCE;

class UserService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                DAARA_TOKEN: getCookie(DAARA_TOKEN_NAME)
            }
        });
        this.data = {};
        this.accessToken = getCookie(DOMAIN_COOKIE_NAME);
    }

    login(id, password) {
        this.data = {
            password,
            username: id
        };
        if (features.accountService.login) {
            this.data = {
                password,
                login: id
            };
            return axios
                .post(`${DAARA_ACCOUNT_SERVICE_URL}/accounts/login`, {
                    ...this.data,
                    source: 'WEB_APP'
                })
                .then((response) => {
                    return mapUser(response.data);
                })
                .catch((e) => {
                    console.log(e);
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .post(`/accounts/login`, this.data, { withCredentials: true })
            .then((response) => {
                return mapUser(response.data);
            })
            .catch((e) => {
                console.log(e);
                throw new UserServiceException(e);
            });
    }

    setRefreshToken(accessToken) {
        return new Promise((resolve, reject) => {
            setCookie(DAARA_REFRESH_TOKEN_NAME, accessToken, 300 / 86400, getDomain());
            resolve(200);
        });
    }

    auth(accessToken) {
        if (features.accountService.login) {
            return this.setRefreshToken(accessToken).then((res) => {
                return refreshAccessToken()
                    .then(({ data }) => {
                        const { access_token } = data;
                        setCookie(DAARA_TOKEN_NAME, access_token, 2, getDomain());

                        return this.ax
                            .post(`${DAARA_ACCOUNT_SERVICE_URL}/auth/accounts`, null, {
                                headers: {
                                    Authorization: `Bearer ${access_token}`
                                }
                            })
                            .then((response) => {
                                response.data.data.basic_info.daaraAccessToken = accessToken;
                                return mapUser(response.data);
                            })
                            .catch((e) => {
                                throw new UserServiceException(e);
                            });
                    })
                    .catch((err) => console.log(err));
            });
        }
        return this.ax
            .post(`/auth/accounts`, undefined, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                response.data.data.basic_info.access_token = accessToken;
                return mapUser(response.data);
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    resetPassword(values) {
        if (features.accountService.resetPassword) {
            const payload = {
                username: values.username,
                redirectUrl: values.redirect_url,
                sourceUrl: values.source_url,
                type: values.type == 2 ? 'PHONE' : 'EMAIL'
            };
            return axios
                .post(`${DAARA_ACCOUNT_SERVICE_URL}/accounts/reset/generate`, payload)
                .then((res) => res.data);
        }
        return this.ax.post('/accounts/reset/generate', values).then((res) => {
            return res.data;
        });
    }

    verifyResetPasswordToken(values) {
        if (features.accountService.resetPassword) {
            return axios
                .get(`${DAARA_ACCOUNT_SERVICE_URL}/accounts/reset/verify?token=${values.code}`)
                .then((res) => res.data.data.code);
        }
        return this.ax
            .post('/accounts/reset/verify', values)
            .then((res) => {
                return res.data.data.code;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getSecurityQuestion(values) {
        if (features.accountService.resetPassword) {
            return axios
                .get(
                    `${DAARA_ACCOUNT_SERVICE_URL}/accounts/reset/question/generate?token=${values.code}`
                )
                .then((res) => res.data.data)
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .post('/accounts/reset/question/generate', values)
            .then((res) => {
                return res.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    verifySecurityQuestion(values) {
        if (features.accountService.resetPassword) {
            const payload = {
                answer: values.answer,
                code: values.code,
                questionId: values.question_id
            };
            return axios
                .post(`${DAARA_ACCOUNT_SERVICE_URL}/accounts/reset/question/verify`, payload)
                .then((res) => res.data.data)
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .post('/accounts/reset/question/verify', values)
            .then((res) => {
                return res.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    resetPasswordNew(values) {
        if (features.accountService.resetPassword) {
            const payload = {
                code: values.code,
                newPassword: values.password,
                confirmPassword: values.confirm_password
            };
            return axios
                .post(`${DAARA_ACCOUNT_SERVICE_URL}/accounts/reset/password`, payload)
                .then((res) => res.data.data)
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .post('/accounts/reset/password', values)
            .then((res) => {
                return res.data.data.token;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getEnrollees(clientId) {
        if (features.enrolleeService.list) {
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/${clientId}/enrollees`
            )
                .then((res) => res.data)
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .get('/enrollees', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getEnrolleeById(uId) {
        if (features.enrolleeService.view) {
            return sendHttpGetRequest(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${uId}`)
                .then((response) => {
                    let enrollee = response.data;
                    const { subscriptionItems } = enrollee;
                    enrollee = {
                        id: enrollee.id,
                        hmo_id: enrollee.hmoId,
                        first_name: enrollee.person.firstName,
                        last_name: enrollee.person.lastName,
                        phone_number: enrollee.person.phoneNumber,
                        email_address: enrollee.person.email,
                        product: {
                            id: subscriptionItems[0].productId,
                            name: subscriptionItems[0].productName
                        },
                        cover_start_date: subscriptionItems[0].subscription.startDate,
                        cover_end_date: subscriptionItems[0].subscription.endDate,
                        profile: enrollee.hasCompleteProfile
                            ? { picture: enrollee.person.pictureUrl, hmo_id: enrollee.hmoId }
                            : null,
                        hash: enrollee.hash,
                        new_product: null,
                        deactivated: enrollee.status === 'DEACTIVATED' ? true : false,
                        extensions: subscriptionItems.map((subscription) => {
                            return {
                                product: {
                                    name: subscription.productName
                                },
                                cover_start_date: subscription.subscription.startDate,
                                cover_end_date: subscription.subscription.endDate,
                                active_status_id: encodeResourceStatus(
                                    subscription.subscription.status
                                )
                            };
                        })
                    };
                    return enrollee;
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .get(`/enrollees/${uId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoices(clientId) {
        if (features.invoiceService.list) {
            return sendHttpGetRequest(`${DAARA_INVOICE_SERVICE_URL}/clients/${clientId}/invoices`)
                .then((response) => response.data)
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .get('/accounts/invoices', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoiceByRefCode(clientId, refCode) {
        if (features.invoiceService.view) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/clients/${clientId}/invoices/${refCode}`
            );
        }
        return this.ax
            .get(`/accounts/invoices/${refCode}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewableExpiredInvoices(clientId) {
        if (features.invoiceService.view) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/clients/${clientId}/invoices?activeStatus=${decodeResourceStatus(
                    4
                ).toUpperCase()}`
            )
                .then((response) => {
                    return response.data;
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .get(`/accounts/invoices?status=4`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoiceEnrollees(refCode) {
        if (features.invoiceService.renew) {
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/invoices/${refCode}/enrollees`
            )
                .then((response) => {
                    return response.data;
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
        return this.ax
            .get(`/accounts/invoices/${refCode}/enrollees`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    addEnrolleeToInvoice(json) {
        if (features.enrolleeService.assign) {
            const { invoiceReference } = json;
            return sendHttpPostRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/${invoiceReference}/enrollee`,
                json
            )
                .then((res) => {
                    return res;
                })
                .catch((e) => {
                    throw new UserServiceException(e, e.response.data.data);
                });
        }

        return this.ax
            .post('/enrollees', json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e, e.response.data.data);
            });
    }

    editEnrolleeDetails(userId, json) {
        return this.ax
            .put(`/enrollees/${userId}`, json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    editEnrolleeSubscription(userId, json) {
        return this.ax
            .put(`/accounts/enrollees/${userId}/subscription`, json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewSuspendedPlans(invoiceRefCode, user_ids) {
        if (features.invoiceService.renew) {
            return sendHttpPostRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/${invoiceRefCode}/renew`,
                { user_ids }
            )
                .then((res) => {
                    return res.data.data;
                })
                .catch((err) => {
                    throw new UserServiceException(err);
                });
        } else {
            return this.ax
                .post(`payment/manual/${invoiceRefCode}`, { user_ids })
                .then((response) => {
                    return response.data.data;
                });
        }
    }

    buyPlansAndExtensions(json) {
        if (features.enrolleeService.purchase) {
            return sendHttpPostRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/subscribe`,
                json
            )
                .then((res) => res)
                .catch((err) => {
                    throw new UserServiceException(err);
                });
        }
        return this.ax
            .post('/cart', json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    changePassword(json) {
        let url, data;
        if (features.accountService.changePassword) {
            url = `${DAARA_ACCOUNT_SERVICE_URL}/change-password`;
            const { confirm_new_password, new_password, old_password } = json;
            data = {
                oldPassword: old_password,
                newPassword: new_password,
                confirmPassword: confirm_new_password
            };
        } else {
            url = `/accounts/change-password`;
            data = json;
        }
        return this.ax
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                if (features.accountService.changePassword) {
                    return 'Password Successfully Changed';
                }
                return response.data.message;
            })
            .catch((e) => {
                if (features.accountService.changePassword) {
                    e.response.data.message = e.response.data.detail;
                }
                throw new UserServiceException(e);
            });
    }

    transferEnrollee(eId) {
        if (features.enrolleeService.transferOwnerShip) {
            return sendHttpPostRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${eId}/transfer`,
                null
            )
                .then((response) => {
                    if (response.data.transferFlag) {
                        const { firstName, lastName } = response.data.person;
                        return `Successful transfer to ${firstName} ${lastName}`;
                    }
                    return response.data.data.message;
                })
                .catch((e) => {
                    e.response.data.message = e.response.data.data
                        ? e.response.data.data.cause
                        : e.response.data.detail;
                    throw new UserServiceException(e);
                });
        } else {
            return this.ax
                .post(`/enrollees/${eId}/transfer`, null, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                })
                .then((response) => {
                    return response.data.data.message;
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        }
    }

    removeEnrolleeExtension(eId, extId) {
        return this.ax
            .delete(`/enrollees/${eId}/extensions/${extId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    removeEnrollee(eId) {
        return this.ax
            .delete(`/enrollees/${eId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }
    getEnrolleeFromhash(hash) {
        return sendHttpGetRequest(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/unhash/${hash}`);
    }
    unhashParams(hash) {
        if (hash) {
            if (features.enrolleeService.completeProfile) {
                return Promise.all([
                    this.getEnrolleeFromhash(hash),
                    utilityService.getProvidersByType(hash)
                ])
                    .then((response) => {
                        const { person } = response[0].data;
                        return {
                            email_address: person.email,
                            phone_number: person.phoneNumber,
                            first_name: person.firstName,
                            last_name: person.lastName,
                            id: response[0].data.id,
                            providers: response[1].data
                        };
                    })
                    .catch((e) => {
                        throw new UserServiceException(e);
                    });
            }
            return this.ax
                .post(
                    '/enrollees/unhash',
                    { hash },
                    {
                        headers: {
                            Authorization: `Bearer ${this.accessToken}`
                        }
                    }
                )
                .then((response) => {
                    return {
                        email_address: response.data.data.email_address,
                        phone_number: response.data.data.phone_number,
                        first_name: response.data.data.first_name,
                        last_name: response.data.data.last_name,
                        is_family_plan: response.data.data.is_family_plan,
                        company_name: response.data.data.company_name,
                        providers: response.data.data.providers
                    };
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        } else {
            return null;
        }
    }

    getRetailClient(email) {
        return sendHttpGetRequest(`${DAARA_CLIENT_SERVICE_URL}/retail-clients?query=${email}`)
            .then(({ data }) => data.content[0])
            .catch((e) => {
                window.location.href = '/logout';
                throw new UserServiceException(e);
            });
    }

    register(values, hash) {
        //delete access_token from post body
        delete values.access_token;
        if (features.enrolleeService.completeProfile) {
            let formValues = {
                id: values.id,
                firstName: values.first_name,
                lastName: values.last_name,
                email: values.email_address,
                phoneNumber: values.primary_phone_number,
                dateOfBirth: values.date_of_birth,
                hasSmartphone: values.has_smartphone === '1' ? true : false,
                address: values.home_address,
                stateId: values.home_address_state_id,
                gender: values.gender && values.gender.toUpperCase(),
                maritalStatus: values.marital_status_id != -1 ? values.marital_status_id : null,
                preferredProviderId: values.preferred_provider_id
            };

            const formData = new FormData();
            formData.append(
                'enrolleeUpdate',
                new Blob([JSON.stringify(formValues)], { type: 'application/json' })
            );
            formData.append('profilePicture', values.profile_picture);

            return sendHttpPutRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${hash}/profile`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
                .then((response) => {
                    return response.data.message;
                })
                .catch((e) => {
                    const { data } = e.response;
                    if (data && data.fieldErrors) {
                        const exception = {
                            response: {
                                status: e.response.status,
                                statusText: 'An Error Occurred',
                                data: { message: data.fieldErrors[0].message }
                            }
                        };
                        throw new UserServiceException(exception);
                    }
                    throw new UserServiceException(e);
                });
        }
        //delete profile picture file
        delete values.profile_picture;
        return this.ax
            .post(`/enrollees/profile`, values, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getRenewalReasons() {
        if (features.invoiceService.subscriptionExpiredReason) {
            return sendHttpGetRequest(`${DAARA_ENROLLEE_SERVICE_URL}/expiry-options`).then(
                (response) => {
                    return response.data;
                }
            );
        }
        return this.ax
            .get(`/variable-billing/options`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    sendRenewalReason(reference_code, reason, clientId) {
        if (features.invoiceService.subscriptionExpiredReason) {
            return sendHttpPostRequest(`${DAARA_ENROLLEE_SERVICE_URL}/variable-billing/reason`, {
                clientId,
                invoiceReferenceCode: reference_code,
                expiryOptionId: reason
            }).then((response) => {
                return response.data;
            });
        }
        return this.ax
            .put(
                `/variable-billing/reason`,
                { reference_code, reason },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getVariableBillingInfo(invoiceRefCode, user_ids = null) {
        let data = { reference_code: invoiceRefCode };
        if (user_ids) data.user_ids = user_ids;
        return this.ax
            .post(`/variable-billing/info`, data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewPlan(invoiceRefCode) {
        return this.ax.post(`payment/manual/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    resetPlan(invoiceRefCode) {
        return this.ax.post(`/retail/renew/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    getPremiumValue(accessToken, invoiceRefCode, clientId) {
        if (features.invoiceService.premiumValue) {
            return sendHttpGetRequest(
                `${DAARA_INVOICE_SERVICE_URL}/invoices/${invoiceRefCode}/client/${clientId}/premium-value`
            ).then((response) => {
                const { saveValue, claimsPremium } = response.data;
                return { data: { saved_value: saveValue, claims_gt_premium: claimsPremium } };
            });
        }
        return this.ax
            .get(`/accounts/invoices/${invoiceRefCode}/premium-value`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    toggleAutoBilling(activate, invoiceRefCode) {
        if (features.invoiceService.update) {
            return sendHttpPutRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/client-subscriptions/${invoiceRefCode}?subscribe=${activate}`
            ).then((response) => {
                return response.data;
            });
        }
        return this.ax
            .post(`/payment/${activate ? 'subscribe' : 'unsubscribe'}/${invoiceRefCode}`)
            .then((response) => {
                return response.data.message;
            });
    }

    changePaymentFrequency(accessToken, newFreq, invoiceRefCode) {
        if (features.invoiceService.update) {
            return sendHttpPutRequest(
                `${DAARA_INVOICE_SERVICE_URL}/invoices/${invoiceRefCode}/frequency?freq=${newFreq}`
            )
                .then((response) => {
                    return response.data.message;
                })
                .catch((e) => {
                    const { data } = e.response;
                    const err = {
                        response: {
                            status: data.status,
                            data: {
                                message: data.message
                            }
                        }
                    };
                    console.log(err);
                    throw new UserServiceException(err);
                });
        }
        return this.ax
            .put(
                `/accounts/invoices/${invoiceRefCode}`,
                { frequency: newFreq },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingPay(reference_code, user_ids, one_time = false) {
        return this.ax
            .post(
                `/variable-billing/pay`,
                {
                    reference_code,
                    user_ids,
                    one_time
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingRefund(refCode, accName, accNumber, bankId) {
        return this.ax
            .post(
                `/enrollees/billing/refund`,
                {
                    reference_code: refCode,
                    account_name: accName,
                    account_number: accNumber,
                    bank_id: bankId
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingDismiss(refCode) {
        return this.ax
            .post(
                `/enrollees/billing/dismiss`,
                {
                    reference_code: refCode
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }
}

function mapUser(json) {
    if (features.accountService.login) {
        return {
            firstName: _.get(json, 'data.basic_info.firstName'),
            lastName: _.get(json, 'data.basic_info.lastName'),
            roles: _.get(json, 'data.roles'),
            email: _.get(json, 'data.basic_info.email'),
            phoneNumber: _.get(json, 'data.basic_info.phoneNumber'),
            credentials: {
                accessToken: _.get(json, 'data.basic_info.daaraAccessToken'),
                refreshToken: _.get(json, 'data.basic_info.daaraRefreshToken'),
                refreshTokenExpire: _.get(json, 'data.basic_info.refreshExpire')
            },
            preferences: {
                allowNotifications: ''
            }
        };
    }
    return {
        firstName: _.get(json, 'data.basic_info.first_name'),
        lastName: _.get(json, 'data.basic_info.last_name'),
        roles: _.get(json, 'data.roles'),
        email: _.get(json, 'data.basic_info.email_address'),
        phoneNumber: _.get(json, 'data.basic_info.phone_number'),
        isInternalUser: _.get(json, 'data.basic_info.is_internal_user'),
        credentials: {
            accessToken: _.get(json, 'data.basic_info.access_token'),
            daaraToken: _.get(json, 'data.basic_info.daara_token'),
            refreshToken: _.get(json, 'data.basic_info.daara_refresh_token')
        },
        preferences: {
            allowNotifications: ''
        }
    };
}

function UserServiceException(e, _meta) {
    if (!e.response)
        e = {
            response: {
                status: 0,
                statusText: 'Unknown Error',
                data: { message: 'An Error Occurred' }
            }
        };
    this.code = e.response.status;
    this.message = e.response.data.message || e.response.statusText;
    this.meta = _meta;
}

export default new UserService();
