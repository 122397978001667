import React from 'react';
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';
import * as _ from 'lodash';
import LoadingScreen from '../components/LoadingScreen';
import { DISPLAY_ACTION_TYPES } from '../actions/actionTypes';
import SidebarContainer from './SidebarContainer';
import EnrolleeComponent from '../components/EnrolleeComponent';
import InvoiceComponent from '../components/InvoiceComponent';
import HelpComponent from '../components/lists/HelpComponent';
import PageNotFoundComponent from '../components/PageNotFoundComponent';
import ChangePasswordComponent from '../components/ChangePasswordComponent';
import NoAccessModal from '../components/NoAccessModal';
import PropsRoute from '../components/utils/PropsRoute';
import { features } from '../services/daara-config.json';
import * as clientActions from '../actions/client';
import Immutable from 'seamless-immutable';
import { connect } from 'react-redux';

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        const { dispatch } = props;
        props.history.listen(() => {
            dispatch({ type: DISPLAY_ACTION_TYPES.HIDE_SIDEBAR });
        });
    }

    componentDidMount() {
        if (features.clientService.load) {
            const { dispatch, user } = this.props;
            if (!user.clientId) {
                dispatch(clientActions.loadClient(user.email));
            }
        }
    }

    renderLoading() {
        return <LoadingScreen />;
    }

    renderDashboard(user, authenticated) {
        const redirectUrl =
            user.roles.filter((role) => role.can_access).length > 0
                ? user.roles.filter((role) => role.can_access)[0].url
                : '/welcome';

        return (
            <div className="dashboard-container">
                {!user.roles.filter((role) => role.name.toLowerCase() === 'user')[0].can_access && (
                    <NoAccessModal redirectUrl={redirectUrl} />
                )}
                <SidebarContainer
                    userName={`${user.firstName} ${user.lastName}`}
                    isEnrollee={
                        user.roles.filter((role) => role.name === 'enrollee' && role.can_access)
                            .length > 0
                    }
                />
                <Switch>
                    <PropsRoute
                        component={EnrolleeComponent}
                        path="/dashboard/enrollees"
                        authenticated={authenticated}
                        user={user}
                    />
                    <PropsRoute
                        component={ChangePasswordComponent}
                        path="/dashboard/change-password"
                        authenticated={authenticated}
                        user={user}
                    />
                    <PropsRoute
                        component={HelpComponent}
                        path="/dashboard/help"
                        authenticated={authenticated}
                        user={user}
                    />
                    <PropsRoute
                        component={InvoiceComponent}
                        path="/dashboard/invoices"
                        authenticated={authenticated}
                        user={user}
                    />
                    <Route
                        path="/dashboard/logout"
                        exact
                        render={(props) => <Redirect to="/logout" />}
                    />
                    <Route
                        path="/dashboard/"
                        exact
                        render={(props) => <Redirect to="/dashboard/enrollees" />}
                    />
                    <Route component={PageNotFoundComponent} />
                    {/*<PrivateRoute component={OverviewContainer} path="/dashboard" authenticated={authenticated} user={user}/>*/}
                </Switch>
            </div>
        );
    }

    render() {
        const { user, client } = this.props;
        let authenticated = true;

        if (features.clientService.load) {
            if (!_.isEmpty(user) && user.clientId) {
                return this.renderDashboard(user, authenticated);
            } else {
                return this.renderLoading();
            }
        } else {
            if (!_.isEmpty(user)) {
                return this.renderDashboard(user, authenticated);
            }
        }
    }
}

const daaraMapStateToProps = ({ client }) => ({
    client: client.client,
    isFetching: client.isFetching,
    error: client.error ? Immutable.asMutable(client.error) : {},
    alert: client.alert
});

export default features.clientService.load
    ? withRouter(connect(daaraMapStateToProps)(DashboardContainer))
    : withRouter(DashboardContainer);
