import { INVOICE_ACTION_TYPES } from './actionTypes';
import userService from '../services/userService';
import { features } from '../services/daara-config.json';

//////////////////////// ACTIONS ///////////////////////////////////

export const getInvoices = () => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES
    };
};

export const getInvoicesSuccess = (invoices) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES_SUCCESS,
        data: invoices
    };
};

export const getInvoicesFailed = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICES_FAIL,
        data: error
    };
};

export const getInvoiceByRefCode = () => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE
    };
};

export const getInvoiceByRefCodeSuccess = (invoice) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_SUCCESS,
        data: invoice
    };
};

export const getInvoiceByRefCodeFailed = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_FAIL,
        data: error
    };
};

export const addEnrolleeToInvoice = () => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_ENROLLEE_TO_INVOICE
    };
};

export const addEnrolleeToInvoiceSuccess = (enrollee) => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_ENROLLEE_TO_INVOICE_SUCCESS,
        data: enrollee
    };
};

export const addEnrolleeToInvoiceFail = (error) => {
    return {
        type: INVOICE_ACTION_TYPES.ADD_ENROLLEE_TO_INVOICE_FAIL,
        data: error
    };
};

//////////////////////// THUNKS ///////////////////////////////////

export const getUserInvoices = (clientId) => {
    if (features.invoiceService.list) {
        return (dispatch) => {
            dispatch(getInvoices());
            userService.getInvoices(clientId).then(
                (invoices) => {
                    dispatch(getInvoicesSuccess(invoices));
                },
                (err) => {
                    dispatch(getInvoicesFailed({ message: err.message, code: err.code }));
                }
            );
        };
    }
    return (dispatch) => {
        dispatch(getInvoices());
        userService.getInvoices().then(
            (invoices) => {
                dispatch(getInvoicesSuccess(invoices));
            },
            (err) => {
                dispatch(getInvoicesFailed({ message: err.message, code: err.code }));
            }
        );
    };
};

export const getUserInvoiceByRefCode = (clientId, refCode) => {
    return (dispatch) => {
        dispatch(getInvoiceByRefCode());
        userService.getInvoiceByRefCode(clientId, refCode).then(
            (invoice) => {
                dispatch(
                    getInvoiceByRefCodeSuccess(
                        features.invoiceService.view ? invoice.data : invoice
                    )
                );
            },
            (err) => {
                dispatch(
                    getInvoiceByRefCodeFailed({
                        message: err.response.data.message,
                        code: err.response.status
                    })
                );
            }
        );
    };
};

export const addEnrolleeToUserInvoice = (json) => {
    return (dispatch) => {
        dispatch(addEnrolleeToInvoice());
        userService.addEnrolleeToInvoice(json).then(
            (response) => {
                dispatch(addEnrolleeToInvoiceSuccess(response));
            },
            (err) => {
                dispatch(
                    addEnrolleeToInvoiceFail({
                        message: err.response.data.message,
                        code: err.response.status
                    })
                );
            }
        );
    };
};
