import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import CheckboxGroup from './inputs/CheckboxGroup';
import { features } from '../../services/daara-config.json';
import { Fragment } from 'react';

class AddSingleEnrolleeForm extends React.Component {
    toggleForMe = (forMe) => {
        const { user, dispatch } = this.props;

        if (forMe) {
            if (forMe === '1') {
                dispatch(change('addSingleEnrolleeForm', 'first_name', user.firstName));
                dispatch(change('addSingleEnrolleeForm', 'last_name', user.lastName));
                dispatch(change('addSingleEnrolleeForm', 'email_address', user.email));
                dispatch(change('addSingleEnrolleeForm', 'phone_number', user.phoneNumber));
                dispatch(change('addSingleEnrolleeForm', 'can_manage', true));
                dispatch(change('addSingleEnrolleeForm', 'can_complete_profile', true));
            } else {
                dispatch(change('addSingleEnrolleeForm', 'first_name', ''));
                dispatch(change('addSingleEnrolleeForm', 'last_name', ''));
                dispatch(change('addSingleEnrolleeForm', 'email_address', ''));
                dispatch(change('addSingleEnrolleeForm', 'phone_number', ''));
                dispatch(change('addSingleEnrolleeForm', 'can_manage', false));
                dispatch(change('addSingleEnrolleeForm', 'can_complete_profile', false));
            }
        }
    };

    render() {
        const { handleSubmit, pristine, submitting, invoicePlans, extensions, forMe, planId } =
            this.props;
        let selectedPlan = invoicePlans.filter((p) => p.id === Number(planId))[0];

        return (
            <form className="dashboard-form" onSubmit={handleSubmit}>
                <div className="dashboard-form-item-wrapper">
                    <div className="dashboard-input-wrap profile-input-wrap ">
                        <span className="dashboard-form-item">
                            <label htmlFor="" className="profile-item__label">
                                Is this plan for you?
                            </label>
                            <span className="dashboard-form__checklist-wrap">
                                <span className="dashboard-form-item__radio-check-btn">
                                    <Field
                                        name="for_me"
                                        component="input"
                                        type="radio"
                                        value="1"
                                        id="yes"
                                        onChange={(e) => this.toggleForMe(e.target.value)}
                                    />
                                    <label htmlFor="yes">Yes</label>
                                </span>
                                <span className="dashboard-form-item__radio-check-btn">
                                    <Field
                                        name="for_me"
                                        component="input"
                                        type="radio"
                                        value="0"
                                        id="no"
                                        onChange={(e) => this.toggleForMe(e.target.value)}
                                    />
                                    <label htmlFor="no">No</label>
                                </span>
                            </span>
                        </span>
                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Firstname
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="text"
                                name="first_name"
                                placeholder=""
                                readOnly={forMe === '1'}
                            />
                        </span>
                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Lastname
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="text"
                                name="last_name"
                                placeholder=""
                                readOnly={forMe === '1'}
                            />
                        </span>
                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                {forMe === '1'
                                    ? 'Email Address'
                                    : 'Email Address (Leave blank, if unavailable)'}
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="email"
                                name="email_address"
                                placeholder=""
                                readOnly={forMe === '1'}
                            />
                        </span>
                    </div>

                    <div className="dashboard-input-wrap profile-input-wrap ">
                        <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Phone Number
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="number"
                                name="phone_number"
                                placeholder=""
                                readOnly={forMe === '1'}
                            />
                        </span>
                        <span className="dashboard-form-item">
                            <label htmlFor="" className="profile-item__label">
                                Select a Plan for the Enrollee
                            </label>
                            <Field
                                component="select"
                                className="dashboard-form__input dashboard-form__select"
                                name="product_id"
                            >
                                {features.enrolleeService.assign ? (
                                    <Fragment>
                                        <option value="0">
                                            Please select a plan for the Enrollee
                                        </option>
                                        {invoicePlans
                                            .filter(
                                                (product) =>
                                                    product.quantity - product.allocatedSlots > 0
                                            )
                                            .map((product) => (
                                                <option
                                                    key={product.id}
                                                    value={product.productReference}
                                                >
                                                    {product.name}
                                                </option>
                                            ))}
                                    </Fragment>
                                ) : (
                                    invoicePlans
                                        .filter((product) => product.no_of_available_slots > 0)
                                        .map((product) => (
                                            <option key={product.id} value={product.id}>
                                                {product.name}
                                            </option>
                                        ))
                                )}
                            </Field>
                        </span>
                        {extensions.length > 0 ? (
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    selectedPlan Add Extra Benefit(s)
                                </label>
                                {selectedPlan ? (
                                    <CheckboxGroup
                                        options={extensions.filter(
                                            (ext) =>
                                                ext.is_available &&
                                                ext.description.indexOf(selectedPlan.name) > -1
                                        )}
                                        name="extensions"
                                    />
                                ) : (
                                    <span className="dashboard-form__checklist-wrap">
                                        <p style={{ color: '#6d6d6d' }}>Please select a plan.</p>
                                    </span>
                                )}
                            </span>
                        ) : (
                            <span className="dashboard-form-item">
                                <span className="dashboard-form__checklist-wrap">
                                    <p style={{ color: '#6d6d6d' }}>
                                        You have not purchased any extra benefit.
                                    </p>
                                </span>
                            </span>
                        )}
                        <Field
                            className="hidden"
                            name="access_token"
                            component="input"
                            style={{ display: 'none' }}
                        />

                        <span className="dashboard-form-item" style={{ marginTop: '0' }}>
                            <br />
                            <span
                                className="dashboard-form__checklist-wrap dashboard-form-item__radio-check-btn dashboard-form-item__check-btn"
                                style={{ display: 'block' }}
                            >
                                <Field
                                    name="can_complete_profile"
                                    id="can_complete_profile"
                                    component="input"
                                    type="checkbox"
                                    disabled={forMe === '1'}
                                />
                                <label htmlFor="can_complete_profile">
                                    <p style={{ width: '82%' }}>
                                        Do you want to complete this person’s profile?
                                    </p>
                                </label>
                                <p className="dashboard-form__small-text">
                                    You will need their date of birth, home address, and picture to
                                    do this.
                                    <br />
                                    Otherwise, we will send them an email with the form
                                </p>
                            </span>
                        </span>

                        <span className="dashboard-form-item" style={{ marginTop: '0' }}>
                            <br />
                            <span
                                className="dashboard-form__checklist-wrap dashboard-form-item__radio-check-btn dashboard-form-item__check-btn"
                                style={{ display: 'block' }}
                            >
                                <Field
                                    name="can_manage"
                                    id="can_manage"
                                    component="input"
                                    type="checkbox"
                                    disabled={forMe === '1'}
                                />
                                <label htmlFor="can_manage">
                                    <p style={{ width: '82%' }}>
                                        Do you want to have this person’s digital ID on your
                                        smartphone ?
                                    </p>
                                </label>
                                <p className="dashboard-form__small-text">
                                    Great for children and elderly parents without smartphones
                                </p>
                            </span>
                        </span>
                    </div>
                </div>
                <button
                    className="dashboard__primary-btn dashboard-form__btn"
                    disabled={pristine || submitting}
                >
                    Submit
                </button>
            </form>
        );
    }
}

AddSingleEnrolleeForm = reduxForm({
  form:"addSingleEnrolleeForm",
  destroyOnUnmount:false
})(AddSingleEnrolleeForm);

const selector = formValueSelector('addSingleEnrolleeForm');

AddSingleEnrolleeForm = connect(state=>({
  forMe: selector(state, 'for_me'),
  planId: selector(state,'plan_id'),
  initialValues:{access_token:state.session.user.credentials.accessToken}
}))(AddSingleEnrolleeForm);

export default AddSingleEnrolleeForm;
