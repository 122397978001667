import React from 'react';
import * as _ from 'lodash';
import userService from '../services/userService';
import querystring from 'querystring';
import VariableBillRefundModal from './modals/VariableBillRefundModal';
import NotificationBanner from './boxes/NotificationBanner';
import AddSingleEmployeeForm from './forms/AddSingleEnrolleeForm';
import LoadingScreen from './LoadingScreen';
import PageNotFound from './PageNotFoundComponent';
import MainContent from './boxes/MainContent';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import VariableBillModal from './modals/VariableBillModal';
import { features } from '../services/daara-config.json';

class AddEnrolleeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            subscriptionId: null,
            invoiceRefCode: querystring.parse(this.props.location.search.substr(1)).refCode,
            invoicePlans: [
                /*{"combo_id":227,"name":"RED BERYL Individual","no_of_enrollees":2,"enrollees_left":2}*/
            ],
            extensions: [
                /*{id:"0",name:"Pregnancy - 4 Weeks"},{id:"0",name:"PEC"},{id:"0",name:"Pregnancy - 6 Weeks"}*/
            ],
            error: null,
            alert: '',
            showVariableBillDialog: { show: false },
            showRefundModal: false
        };
    }

    componentDidMount() {
        this.getPlans();
    }

    getPlans = () => {
        const { invoiceRefCode } = this.state;

        this.setLoading(true);
        if (features.enrolleeService.assign) {
            const {
                user: { clientId }
            } = this.props;
            userService.getInvoiceByRefCode(clientId, invoiceRefCode).then(
                (invoice) => {
                    const _invoice = invoice.data;
                    this.setState({
                        isLoading: false,
                        invoicePlans: _invoice.invoiceItems,
                        error: null
                    });
                },
                (err) => {
                    this.setNotification(err.message, 'error');
                }
            );
        } else {
            userService.getInvoiceByRefCode(invoiceRefCode).then(
                (invoice) => {
                    this.setState({
                        isLoading: false,
                        invoicePlans: invoice.products,
                        subscriptionId: invoice.subscription_id,
                        error: null
                    });
                },
                (err) => {
                    this.setNotification(err.message, 'error');
                }
            );
        }
    };

    renderInvoiceNotFound() {
        return (
            <MainContent>
                <PageNotFound />
            </MainContent>
        );
    }

    renderLoading() {
        return (
            <MainContent>
                <LoadingScreen />
            </MainContent>
        );
    }

    renderVariableDialog = (showDialog, content) => {
        this.setState({
            showVariableBillDialog: {
                show: showDialog,
                content
            }
        });
    };

    renderRefundModal = (showModal) => {
        this.setState({
            showRefundModal: showModal,
            showVariableBillDialog: {
                show: false,
                content: this.state.showVariableBillDialog.content
            }
        });
    };

    onSingleFormSubmit = (values) => {
        const { subscriptionId } = this.state;
        const { history } = this.props;

        this.setLoading(true);
        let _values = values;
        if (features.enrolleeService.assign) {
            const {
                user: { clientId }
            } = this.props;
            const { invoiceRefCode } = this.state;
            const {
                first_name,
                last_name,
                email_address,
                phone_number,
                for_me,
                can_manage,
                product_id
            } = values;

            _values = {
                invoiceReference: invoiceRefCode,
                firstName: first_name,
                lastName: last_name,
                email: email_address,
                phoneNumber: phone_number,
                clientId: clientId,
                subscriptionItemId: subscriptionId,
                forMe: for_me === '1',
                canManage: can_manage,
                productId: product_id
            };
        }

        userService
            .addEnrolleeToInvoice({ ..._values, subscription_id: subscriptionId })
            .then((response) => {
                if (features.enrolleeService.assign) {
                    const {
                        data: { data, message }
                    } = response;
                    const { productName, availableSlot, enrolleeId, hash, productId } = data;
                    const msg = `${message} to ${productName} plan. You have ${availableSlot} slots left on this plan.`;

                    this.setLoading(false);
                    this.setNotification(msg, 'success');

                    const newPlans = this.state.invoicePlans;
                    newPlans.forEach((p) => {
                        if (p.id === Number(productId)) p.allocatedSlots--;
                    });

                    this.setState({
                        invoicePlans: newPlans
                    });
                    if (values.can_manage) {
                        history.push(
                            `/dashboard/enrollees/${enrolleeId}/complete-profile?hash=${hash}`
                        );
                    }
                } else {
                    const { name, number_of_slots_left, message } = response;
                    const msg = `${message} to ${name} plan. You have ${number_of_slots_left} slots left on this plan.`;
                    this.setLoading(false);
                    this.setNotification(msg, 'success');
                    const newPlans = this.state.invoicePlans;
                    newPlans.forEach((p) => {
                        if (p.id === Number(response.product_id)) p.no_of_available_slots--;
                    });
                    this.setState({
                        invoicePlans: newPlans
                    });
                    if (values.can_manage) {
                        console.log(response);
                        history.push(
                            `/dashboard/enrollees/${response.user_id}/complete-profile?hash=${response.hash}`
                        );
                    }
                }
            })
            .catch((e) => {
                if (e.code === 409) {
                    this.renderVariableDialog(true, e.meta);
                    this.setLoading(false);
                } else {
                    this.setLoading(false);
                    this.setNotification(e.message, 'error');
                }
            });
    };

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    };

    setNotification = (message, type) => {
        if (type === 'success') {
            this.setState({
                alert: message,
                error: null
            });
        } else {
            this.setState({
                error: {
                    message: message
                },
                alert: ''
            });
        }
    };

    onPay = () => {
        this.renderVariableDialog(false, null);
        this.setLoading(true);
        userService
            .variableBillingPay(this.state.showVariableBillDialog.content.reference_code)
            .then((response) => {
                window.location.href = response.redirect_url;
            })
            .catch((e) => {
                this.setLoading(false);
                this.setNotification(e.message, 'error');
            });
    };

    onRefund = (values) => {
        this.renderRefundModal(false);
        this.setLoading(true);
        userService
            .variableBillingRefund(
                this.state.showVariableBillDialog.content.reference_code,
                values.account_name,
                values.account_number,
                values.bank_id
            )
            .then((message) => {
                this.setLoading(false);
                this.setNotification(message, 'success');
            })
            .catch((e) => {
                this.setLoading(false);
                this.setNotification(e.message, 'error');
            });
    };

    onDismiss = () => {
        this.renderVariableDialog(false, null);
        this.setLoading(true);
        userService
            .variableBillingDismiss(this.state.showVariableBillDialog.content.reference_code)
            .then(() => {
                this.setLoading(false);
            })
            .catch((e) => {
                this.setLoading(false);
                this.setNotification(e.message, 'error');
            });
    };

    render() {
        const {
            invoicePlans,
            isLoading,
            error,
            alert,
            extensions,
            showVariableBillDialog,
            showRefundModal
        } = this.state;

        if (isLoading) return this.renderLoading();

        if (_.isEmpty(invoicePlans)) return this.renderInvoiceNotFound();

        return (
            <MainContent>
                <PageHeader title="Add Enrollee(s)" />
                <PageBody>
                    {(!_.isEmpty(error) || alert) && (
                        <NotificationBanner
                            title={error ? 'Error!' : 'Notification!'}
                            message={error ? error.message : alert}
                            type={error ? 'error' : 'success'}
                            onDismiss={() => this.setNotification(null, 'success')} //hack to dismiss notification banner
                        />
                    )}

                    {showVariableBillDialog.content && (
                        <VariableBillModal
                            amount={showVariableBillDialog.content.amount}
                            total={showVariableBillDialog.content.total_amount}
                            duration={showVariableBillDialog.content.payment_blocks}
                            onPay={this.onPay}
                            onClose={() => this.renderVariableDialog(false, null)}
                            onRefund={() => this.renderRefundModal(true)}
                            onDismiss={this.onDismiss}
                            open={showVariableBillDialog.show}
                        />
                    )}

                    <VariableBillRefundModal
                        onClose={() => this.renderRefundModal(false)}
                        onSubmit={this.onRefund}
                        open={showRefundModal}
                    />

                    <div className="dashboard-card">
                        <h2 className="dashboard-card__title-text">
                            Fill the form below and click submit to add one enrollee at a time
                        </h2>

                        <AddSingleEmployeeForm
                            invoicePlans={invoicePlans}
                            extensions={extensions}
                            user={this.props.user}
                            onSubmit={this.onSingleFormSubmit}
                        />
                    </div>

                    <div className="dashboard-card" style={{ marginTop: '2rem' }}>
                        <h2 className="dashboard-card__title-text">Invoice Plan Summary</h2>
                        <div className="dashboard-table-wrap">
                            <div className="dashboard-table-overflow">
                                <table className="dashboard-table">
                                    <thead>
                                        <tr>
                                            <th className="dashboard-table-item dashboard-table-item__header">
                                                Plan Description
                                            </th>
                                            <th className="dashboard-table-item dashboard-table-item__header">
                                                Total
                                            </th>
                                            <th className="dashboard-table-item dashboard-table-item__header">
                                                Added
                                            </th>
                                            <th className="dashboard-table-item dashboard-table-item__header">
                                                Remaining
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicePlans.map((plan) => (
                                            <tr key={plan.id}>
                                                <td className="dashboard-table-item dashboard-table-item__body">
                                                    {plan.name}
                                                </td>
                                                <td className="dashboard-table-item dashboard-table-item__body">
                                                    {features.enrolleeService.assign
                                                        ? plan.quantity
                                                        : plan.no_of_enrollees}
                                                </td>
                                                <td className="dashboard-table-item dashboard-table-item__body">
                                                    {features.enrolleeService.assign
                                                        ? plan.allocatedSlots
                                                        : plan.no_of_enrollees}
                                                </td>
                                                <td className="dashboard-table-item dashboard-table-item__body">
                                                    {features.enrolleeService.assign
                                                        ? plan.quantity - plan.allocatedSlots
                                                        : plan.no_of_enrollees}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </PageBody>
            </MainContent>
        );
    }
}

export default AddEnrolleeComponent;
