import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import Immutable from 'seamless-immutable';
import numberFormatter from 'number-formatter';
import { decodePaymentFrequency, getPaymentFrequency, encodeResourceStatus } from '../services/utilityService';
import * as invoiceActions from '../actions/invoice';
import userService from '../services/userService';
import InvoiceDetailsComponent from '../components/InvoiceDetailsComponent';
import InvoicePlansComponent from '../components/InvoicePlansComponent';
import PageNotFound from '../components/PageNotFoundComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import NotificationBanner from '../components/boxes/NotificationBanner';
import Modal from '../components/boxes/Modal';
import PaymentFrequencySelectorComponent from '../components/PaymentFrequencySelectorComponent';
import InvoiceRenewalContainer from './InvoiceRenewalContainer';
import AutoBillingModal from '../components/modals/AutoBillingModal';
import { features } from '../services/daara-config.json';

class InvoiceDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addEmployee: false,
            isLoading: false,
            message: null,
            showPaymentFrequencyModal: false,
            notification: null,
            showSubscriptionModal: false,
            premiumValue: {}
            //selectedInvoice:{"invoice":{"total_price":"12500.00","reference_code":"INV68FC7320","start_date":"2018-04-09","end_date":"2018-05-09","issue_time":"2018-04-09 17:06:34","amount_paid":"12500.00","payment_status":3},"plans":[{"combo_price":"2500.00","combo_name":"RED BERYL Individual","no_of_enrollees":1},{"combo_price":"5000.00","combo_name":"ALEXANDRITE Individual","no_of_enrollees":2}]}
        };
    }

    componentDidMount() {
        const { dispatch, user, match } = this.props;
        dispatch(invoiceActions.getUserInvoiceByRefCode(user.clientId, match.params.invoiceId)); // removed user.credentials.accessToken to prevent undefined error
        userService
            .getPremiumValue(user.credentials.accessToken, match.params.invoiceId, user.clientId)
            .then((response) => {
                this.setState({
                    premiumValue: { ...response.data }
                });
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    }

    renderLoading() {
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    renderInvoiceNotFound() {
        return (
            <MainContent>
                <PageNotFound />
            </MainContent>
        );
    }

    renewPlan = () => {
        const { selectedInvoice } = this.props;
        this.setState({
            isLoading: true
        });
        userService
            .renewPlan(selectedInvoice.reference_code)
            .then((data) => {
                this.setState({
                    notification: null
                });
                window.location.href = data.authorization_url;
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    notification: { type: 'error', message: e.response.data.message }
                });
            });
    };

    reactivatePlan = () => {
        const { history } = this.props;
        this.setState({
            isLoading: true
        });
        history.push('/dashboard/invoices/edit/new-invoice');
    };

    buyNewPlanAndAssign = (json) => {
        this.setState({
            isLoading: true
        });
        userService.buyPlansAndExtensions(json).then(
            (response) => {
                if (features.invoiceService.reactivate) {
                    const {
                      data: { authorizationUrl, useNewCard }
                    } = response.data;
                    if (useNewCard) {
                      return window.location.href = authorizationUrl;
                    }
                    return window.location.reload(true);
                }
                if (!response.use_new_card) {
                    window.location.href = '/payment-successful';
                } else {
                    window.location.href = response.authorization_url;
                }
            },
            (e) => {
                this.setState({
                    isLoading: false,
                    notification: { type: 'error', message: e.message }
                });
            }
        );
    };

    variableBilling = (enrolleeIds, oneTime) => {
        const { selectedInvoice } = this.props;
        this.setState({
            isLoading: true
        });
        userService
            .variableBillingPay(selectedInvoice.reference_code, enrolleeIds, oneTime)
            .then((data) => {
                this.setState({
                    notification: null
                });
                window.location.href = data.authorization_url;
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    notification: { type: 'error', message: e.message }
                });
            });
    };

    togglePaymentFrequencyModal = () => {
        this.setState({
            showPaymentFrequencyModal: !this.state.showPaymentFrequencyModal
        });
    };

    onFrequencyChanged = (err, newFreq, message) => {
        const { dispatch, match, user } = this.props;
        this.setMessage({
            type: err ? 'Error' : 'Success',
            message: err ? err : message
        });
        this.togglePaymentFrequencyModal();

        dispatch(invoiceActions.getUserInvoiceByRefCode(user.clientId, match.params.invoiceId));
    };

    onAddEnrollee = () => {
        const { selectedInvoice, history } = this.props;
        const referenceCode = features.enrolleeService.assign
            ? selectedInvoice.referenceCode
            : selectedInvoice.reference_code;
        history.push(`/dashboard/invoices/edit/add-enrollee?refCode=${referenceCode}`);
    };

    toggleSubscription = () => {
        this.setState({
            showSubscriptionModal: !this.state.showSubscriptionModal
        });
    };

    autoBillingSubscription = () => {
        const { selectedInvoice } = this.props;
        this.setState({
            isLoading: true
        });
        //autobill: 1 == subscribed; 0 = unsubscribed
        const autobill = features.invoiceService.update
            ? !selectedInvoice.autoBill
            : selectedInvoice.autobill !== 1;

        const referenceCode = features.invoiceService.update
            ? selectedInvoice.referenceCode
            : selectedInvoice.reference_code;

        userService
            .toggleAutoBilling(autobill, referenceCode)
            .then((data) => {
                window.location.reload(true);
            })
            .catch((e) => {
                this.setMessage({ type: 'error', message: e.response.data.message });
                this.setState({
                    isLoading: false
                });
            });
    };

    setMessage = (notification) => {
        this.setState({
            notification
        });
    };

    render() {
        const { error, selectedInvoice, user } = this.props;
        const {
            isLoading,
            notification,
            showPaymentFrequencyModal,
            showSubscriptionModal,
            premiumValue
        } = this.state;
        if (error && error.code === 404) return this.renderInvoiceNotFound();

        if (isLoading) return this.renderLoading();

        if (!_.isEmpty(selectedInvoice)) {
            selectedInvoice.active_status_id = features.invoiceService.view ? 
            encodeResourceStatus(selectedInvoice.activeStatus) : selectedInvoice.active_status_id ;
            if (selectedInvoice.active_status_id === 5 || selectedInvoice.active_status_id === 6) {
                return (
                    <MainContent>
                        <PageHeader title="Invoice Renewal" />
                        <PageBody>
                            {notification && (
                                <NotificationBanner
                                    title={notification.type}
                                    message={notification.message}
                                    type={notification.type.toLowerCase()}
                                    onDismiss={() => this.setMessage(null)}
                                />
                            )}
                            <InvoiceRenewalContainer
                                user={user}
                                invoice={features.invoiceService.view ?
                                    {
                                        active_status_id: selectedInvoice.active_status_id,
                                        payment_frequency_id: getPaymentFrequency(selectedInvoice.metadata).toLowerCase(),
                                        reference_code: selectedInvoice.referenceCode,
                                        end_date: selectedInvoice.endDate
                                    }
                                    :
                                    selectedInvoice
                                }
                                onReactivate={this.reactivatePlan}
                                onRenew={this.renewPlan}
                                onBuyNewPlan={this.buyNewPlanAndAssign}
                                onVariableBilling={this.variableBilling}
                            />
                        </PageBody>
                    </MainContent>
                );
            }

            return (
                <MainContent>
                    <PageHeader title="Invoice Details" />
                    <PageBody>
                        {notification && (
                            <NotificationBanner
                                title={notification.type}
                                message={notification.message}
                                type={notification.type.toLowerCase()}
                                onDismiss={() => this.setMessage(null)}
                            />
                        )}
                        {showPaymentFrequencyModal && (
                            <FrequencyModal
                                onClose={this.togglePaymentFrequencyModal}
                                onFrequencyChanged={this.onFrequencyChanged}
                                invoice={selectedInvoice}
                                user={user}
                                selectedFrequency={
                                    features.invoiceService.view
                                        ? getPaymentFrequency(
                                              selectedInvoice.metadata
                                          ).toLowerCase()
                                        : decodePaymentFrequency(
                                              selectedInvoice.payment_frequency_id
                                          ).toLowerCase()
                                }
                            />
                        )}
                        {showSubscriptionModal && (
                            <AutoBillingModal
                                title="Cancel Autobilling"
                                onClose={this.toggleSubscription}
                                isVisible={showSubscriptionModal}
                                content={
                                    premiumValue.claims_gt_premium
                                        ? `We have saved you a total of ${'\u20A6'}${numberFormatter(
                                              '#,###.',
                                              premiumValue.saved_value
                                          )} on health bills.`
                                        : `On average we save every enrollee ${'\u20A6'}${numberFormatter(
                                              '#,###.',
                                              premiumValue.saved_value
                                          )} per year.`
                                }
                                onYes={this.autoBillingSubscription}
                            />
                        )}
                        {features.invoiceService.view
                            ? selectedInvoice.newFrequency && (
                                  <div
                                      style={{
                                          padding: '2rem',
                                          background: '#e2e2e2',
                                          color: '#101010',
                                          fontSize: '1.2rem',
                                          marginBottom: '2rem'
                                      }}
                                  >
                                      <p>
                                          PLEASE NOTE: Your payment frequency will change to{' '}
                                          <b>{selectedInvoice.newFrequency.toUpperCase()}</b> by
                                          your next renewal.
                                      </p>
                                  </div>
                              )
                            : selectedInvoice.new_frequency_id && (
                                  <div
                                      style={{
                                          padding: '2rem',
                                          background: '#e2e2e2',
                                          color: '#101010',
                                          fontSize: '1.2rem',
                                          marginBottom: '2rem'
                                      }}
                                  >
                                      <p>
                                          PLEASE NOTE: Your payment frequency will change to{' '}
                                          <b>
                                              {decodePaymentFrequency(
                                                  selectedInvoice.new_frequency_id
                                              ).toUpperCase()}
                                          </b>{' '}
                                          by your next renewal.
                                      </p>
                                  </div>
                              )}

                        <div className="dashboard-card dashboard-table-wrap">
                            <h2 className="dashboard-card__title-text">
                                Invoice Details for{' '}
                                {features.invoiceService.view
                                    ? selectedInvoice.referenceCode
                                    : selectedInvoice.reference_code}
                            </h2>
                            <div className="dashboard-control">
                                <p
                                    className="dashboard-card__title-text"
                                    style={{ marginBottom: '0' }}
                                >
                                    You pay{' '}
                                    {features.invoiceService.view
                                        ? getPaymentFrequency(selectedInvoice.metadata)
                                        : decodePaymentFrequency(
                                              selectedInvoice.payment_frequency_id
                                          )}
                                </p>

                                <button
                                    className="dashboard__primary-btn dashboard-control__button"
                                    onClick={this.togglePaymentFrequencyModal}
                                >
                                    Change Payment Frequency
                                </button>
                            </div>

                            <InvoiceDetailsComponent
                                invoice={selectedInvoice}
                                onToggleSubscription={this.toggleSubscription}
                                autobillingSubscription={this.autoBillingSubscription}
                            />
                        </div>
                        <div
                            className="dashboard-card dashboard-table-wrap"
                            style={{ marginTop: '2rem' }}
                        >
                            <InvoicePlansComponent
                                addEnrollee={this.onAddEnrollee}
                                plans={
                                    features.invoiceService.view
                                        ? selectedInvoice.invoiceItems
                                        : selectedInvoice.products
                                }
                            />
                        </div>
                    </PageBody>
                </MainContent>
            );
        }
        return this.renderLoading();
    }
}

const FrequencyModal = ({ selectedFrequency, onClose, invoice, user, onFrequencyChanged }) => {
    return (
        <Modal open={true} onClose={onClose} title="Change Payment Frequency">
            {/* show frequency options...along with their respective prices */}
            <PaymentFrequencySelectorComponent
                user={user}
                invoice={invoice}
                exclude={selectedFrequency}
                onFrequencyChanged={onFrequencyChanged}
            />
        </Modal>
    );
};

const mapStateToProps = ({ invoice }) => ({
    isFetching: invoice.isFetching,
    selectedInvoice: invoice.selectedInvoice
        ? Immutable.asMutable(invoice.selectedInvoice, { deep: true })
        : {},
    error: invoice.error
});

export default withRouter(connect(mapStateToProps)(InvoiceDetailsContainer));
