import React from 'react';



export const ItemContextSwitcher = ({items, selectedItem, onItemSelected}) => (
  <div className="content-switcher plan_duration_btn_wrapper">
    {items.map((item, index) => (
      <button 
        key={`${item.id}_${index}`}
        className={`
          dashboard-content-switcher__btn
          ${index === 0 ? 'content-switcher__btn_left-curve' : ''}
          ${index === items.length -1 ? 'content-switcher__btn_right-curve' : ''}
          ${(selectedItem && selectedItem.id === item.id) ? "content-switcher__btn_active" : ""}
        `}
        onClick={() => onItemSelected(item)}>
        {item.name}
      </button> 
    ))}
  </div>
)
