import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';
import { features } from '../services/daara-config.json';

const InvoicePlansComponent = ({ addEnrollee, plans }) => (
    <div className="dashboard-table-overflow">
        <h2
            className="dashboard-card__title-text"
            style={{
                display: 'flex',
                height: 'fit-content',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            Invoice Breakdown
            {plans.map((p) => p.no_of_available_slots).reduce((acc, cur) => acc + cur, 0) > 0 && (
                <span>
                    <button
                        onClick={addEnrollee}
                        className="dashboard__primary-btn dashboard-control__button"
                        style={{ marginLeft: '2rem' }}
                    >
                        Add Enrollee
                    </button>
                </span>
            )}
        </h2>
        <table className="dashboard-table">
            <thead>
                <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Plan Description
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Enrollee(s)
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Unit Price
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Available Slots
                    </th>
                </tr>
            </thead>
            <tbody>
                {plans.map((plan, index) => (
                    <tr key={index}>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.name}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.no_of_enrollees}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            &#8358;{numberFormatter('#,###.00', plan.price)}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.no_of_available_slots}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const InvoicePlans = ({ addEnrollee, plans }) => (
    <div className="dashboard-table-overflow">
        <h2
            className="dashboard-card__title-text"
            style={{
                display: 'flex',
                height: 'fit-content',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            Invoice Breakdown
            {plans.map((p) => p.availableSlot).reduce((acc, cur) => acc + cur, 0) > 0 && (
                <span>
                    <button
                        onClick={addEnrollee}
                        className="dashboard__primary-btn dashboard-control__button"
                        style={{ marginLeft: '2rem' }}
                    >
                        Add Enrollee
                    </button>
                </span>
            )}
        </h2>
        <table className="dashboard-table">
            <thead>
                <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Plan Description
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Enrollee(s)
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Unit Price
                    </th>
                    <th className="dashboard-table-item dashboard-table-item__header">
                        Available Slots
                    </th>
                </tr>
            </thead>
            <tbody>
                {plans.map((plan, index) => (
                    <tr key={index}>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.name}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.allocatedSlots}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            &#8358;{numberFormatter('#,###.00', plan.unitPrice)}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {plan.allocatedSlots != null && plan.quantity
                                ? plan.quantity - plan.allocatedSlots
                                : '-'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

InvoicePlansComponent.propTypes = {
    plans: PropTypes.array
};

InvoicePlans.propTypes = {
    plans: PropTypes.array
};

export default features.invoiceService.view ? InvoicePlans : InvoicePlansComponent;
