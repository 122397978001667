import { CLIENT_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isFetching: false,
    client: {},
    error: {},
    alert: ''
});

export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL:
            return Immutable(state).merge({
                isFetching: true,
                error: null,
                alert: ''
            });
        case CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL_SUCCESS: {
            return Immutable(state).merge({
                isFetching: false,
                client: action.data,
                error: null,
                alert: ''
            });
        }
        case CLIENT_ACTION_TYPES.GET_CLIENT_BY_EMAIL_FAILED:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data,
                alert: ''
            });
        default:
            return state;
    }
}
