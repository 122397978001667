import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';

let LoginForm = (props) => {

    const {handleSubmit, pristine, submitting } = props;

    return (
        <form action="" className="dashboard-form form" onSubmit={handleSubmit}>
          <span>
              <Field component="input" className="form-input" type="text" name="email" placeholder="Email address or HMO ID" required/>
          </span>
          <span>
              <Field component="input" className="form-input" type="password" name="password" placeholder="Password" required/>
          </span>

          <input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value="Login" />
        </form>
    )
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func
};

LoginForm = reduxForm({
    form:'loginForm',
    destroyOnUnmount:false,
    initialValues: {remember_me: true}
})(LoginForm);

export default LoginForm;