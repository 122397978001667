import axios from 'axios';
import Cookies from 'js-cookie';
import { capitalize } from 'lodash';
import { sendHttpGetRequest, sendHttpPostRequest } from './axiosInterceptor';
import { states, occupations, maritalStatuses } from './constants';
import { features } from './daara-config.json';

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getEnrolleeDashboardUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:3001';
        case 'v3test':
        case 'development':
            return 'https://dashboard.develop.reliancehmo.com';
        case 'staging':
            return 'https://dashboard.staging.reliancehmo.com';
        case 'daara':
            return 'https://dashboard.daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://dashboard.daara.staging.reliancehmo.com';
        default:
            return 'https://dashboard.reliancehmo.com';
    }
};

export const getWebsiteUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:4000';
        case 'v3test':
        case 'development':
            return 'https://develop.reliancehmo.com';
        case 'staging':
            return 'https://staging.reliancehmo.com';
        case 'daara':
            return 'https://daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://daara.staging.reliancehmo.com';
        default:
            return 'https://reliancehmo.com';
    }
};

export const getSubDomain = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'v3test':
        case 'development':
            return 'develop.';
        case 'staging':
            return 'staging.';
        case 'daara':
            return 'daara.develop.';
        case 'daara-staging':
            return 'daara.staging.';
        default:
            return '';
    }
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;

export const DAARA_TOKEN_NAME = process.env.REACT_APP_DAARA_TOKEN_NAME;

export const DAARA_PRODUCT_SERVICE_URL = process.env.REACT_APP_DAARA_PRODUCT_SERVICE_URL;

export const DAARA_PROVIDER_SERVICE_URL = process.env.REACT_APP_DAARA_PROVIDER_SERVICE_URL;

export const DAARA_CLIENT_SERVICE_URL = process.env.REACT_APP_DAARA_CLIENT_SERVICE_URL;

export const DAARA_ENROLLEE_SERVICE_URL = process.env.REACT_APP_DAARA_ENROLLEE_SERVICE_URL;

export const DAARA_INVOICE_SERVICE_URL = process.env.REACT_APP_DAARA_INVOICE_SERVICE_URL;

export const DAARA_FILE_SERVICE_URL = process.env.REACT_APP_DAARA_FILE_SERVICE_URL;

export const DAARA_ACCOUNT_SERVICE_URL = process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL;

export const DAARA_REFRESH_TOKEN_NAME = process.env.REACT_APP_DAARA_REFRESH_TOKEN_NAME;

export const decodeResourceStatus = (statusCode) => {
    switch (statusCode) {
        case 1:
            return 'active';
        case 2:
            return 'pending';
        case 3:
            return 'deleted';
        case 4:
            return 'suspended';
        case 5:
            return 'expired';
        case 6:
            return 'deactivated';
        case 7:
            return 'delayed';
        default:
            return '';
    }
};

export const encodeResourceStatus = (statusCode) => {
    switch (statusCode) {
        case 'ACTIVE':
            return 1;
        case 'PENDING':
            return 2;
        case 'DELETED':
            return 3;
        case 'SUSPENDED':
            return 4;
        case 'EXPIRED':
            return 5;
        case 'DEACTIVATED':
            return 6;
        case 'DELAYED':
            return 7;
        default:
            return 0;
    }
};

export const decodePaymentFrequency = (freqCode) => {
    switch (freqCode) {
        case 1:
            return 'Daily';
        case 2:
            return 'Weekly';
        case 3:
            return 'Monthly';
        case 4:
            return 'Yearly';
        case 5:
            return 'Biennially';
        case 6:
            return 'Endlessly';
        case 7:
            return 'Quarterly';
        default:
            return '';
    }
};

export const getPaymentFrequency = (freq) => {
    const freqCode = JSON.parse(freq)['payment_frequency'];
    switch (freqCode) {
        case 'm':
            return 'Monthly';
        case 'y':
            return 'Yearly';
        case 'q':
            return 'Quarterly';
        case 'b':
            return 'Biannially';
        default:
            return features.invoiceService.list ? capitalize(freqCode) : '';
    }
};

export const getPaymentFrequencyFromDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let freq = end.getMonth() - start.getMonth() + 12 * (end.getFullYear() - start.getFullYear());

    switch (freq) {
        case 1:
            return 'Monthly';
        case 3:
            return 'Quarterly';
        case 12:
            return 'Yearly';
        case 6:
            return 'Biannualy';
        case 4:
            return 'Triannualy';
        default:
            return 'unknownFrequency';
    }
};

export const getDiscountedPrice = (price, discount) => {
    return price - (discount / 100) * price;
};

export const setCookie = (name, value, exDays, domain, path, sameSite, secure) => {
    if (!domain) domain = window.location.hostname;
    if (!path) path = '/';
    Cookies.set(name, value, {
        path,
        domain,
        expires: exDays,
        sameSite: sameSite,
        secure: secure
    });
};

export const getCookie = (_name) => {
    return Cookies.get(_name);
};

export const deleteCookie = (name, domain) => {
    Cookies.remove(name, { domain });
};

class UtilityService {
    getOccupations() {
        return Promise.resolve(occupations);
    }

    getStates() {
        return Promise.resolve(states);
    }

    getMaritalStatuses() {
        if (features.enrolleeService.completeProfile) {
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/marital-status`
            ).then((response) => {
                const { data } = response;
                let result = data.map((status, index) => ({ id: index, name: capitalize(status) }));
                return result;
            });
        }
        return Promise.resolve(maritalStatuses);
    }

    getUtilities() {
        return Promise.all([
            this.getOccupations(),
            this.getStates(),
            this.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                states: responses[1].data,
                mStatuses: features.enrolleeService.completeProfile
                    ? responses[2]
                    : responses[2].data
            };
        });
    }

    getPlans(type) {
        if (features.productService.load) {
            return sendHttpGetRequest(
                `${DAARA_PRODUCT_SERVICE_URL}/products/retails${type ? `?query=${type}` : ''}`
            ).then((response) => {
                return response.data;
            });
        } else {
            return axios
                .get(`${BASE_URL_RELIANCE}/products${type ? `?type=${type}` : ''}`)
                .then((response) => {
                    return response.data.data;
                });
        }
    }

    getPlanById(id, PLANS) {
        if (!PLANS) {
            this.getPlans().then((plans) => this.getPlanById(id, plans));
        } else {
            return PLANS.filter((plan) => plan.id === id)[0];
        }
    }

    getProviders(productId) {
        if (features.providerService.load) {
            return sendHttpGetRequest(
                `${DAARA_PROVIDER_SERVICE_URL}/providers/retail?product_id=${productId}`
            ).then((response) => {
                return response.data;
            });
        }
        return axios
            .get(`${BASE_URL_RELIANCE}/providers?product_id=${productId}`)
            .then((response) => {
                return response.data.data;
            });
    }

    getProvidersByType(hash, providerTypeId) {
        return sendHttpGetRequest(
            `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${hash}/providers/${
                providerTypeId ? '?type_id=' + providerTypeId : ''
            }`
        );
    }

    getBanks() {
        return axios.get(`${BASE_URL_RELIANCE}/utility/banks`).then((response) => {
            return response.data.data;
        });
    }

    uploadFile(accessToken, file, uploadType) {
        const formData = new FormData();

        if (features.fileService.upload) {
            formData.append('files', file);
            return sendHttpPostRequest(`${DAARA_FILE_SERVICE_URL}/upload-multiple/image`, formData)
                .then((response) => {
                    const { key } = response.data[0];
                    return key.substr(key.lastIndexOf('/') + 1);
                })
                .catch((e) => {
                    return {
                        message: e.response.data.message || 'An Error Occurred',
                        code: e.response.status
                    };
                });
        }

        formData.append('file', file);
        formData.append('file_use', uploadType);
        return axios
            .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                header: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.filename;
            })
            .catch((e) => {
                return {
                    message: e.response.data.message || 'An Error Occurred',
                    code: e.response.status
                };
            });
    }
}

export default new UtilityService();
