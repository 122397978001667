import React from 'react';
import {Switch, withRouter} from 'react-router-dom';
import PropsRoute from './utils/PropsRoute';
import EnrolleeDetailsContainer from '../containers/EnrolleeDetailContainer';
import EnrolleeListContainer from '../containers/EnrolleeListContainer';
import SignUpContainer from "../containers/SignUpContainer";

const EnrolleeComponent = ({match, user}) => (
    <Switch>
        <PropsRoute exact path={`${match.url}/:enrolleeId/complete-profile`} component={SignUpContainer} user={user}/>
        <PropsRoute exact path={`${match.url}/:enrolleeId`} component={EnrolleeDetailsContainer} user={user}/>
        <PropsRoute path={match.url} component={EnrolleeListContainer} user={user}/>
    </Switch>
);

export default withRouter(EnrolleeComponent);